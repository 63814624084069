import React from 'react';
import Ale   from '../ale-sdk/ale-mobile'

import { TextField, Stack, Separator, MessageBar, MessageBarType,PrimaryButton } from 'office-ui-fabric-react';

const style = {
    stackitem : {
        height: 55,
        display: 'flex',
        alignItems: 'left',
        justifyContent: 'center',
    },
    stackbutton : {
        height: 55,
        display: 'flex',
        alignItems: 'right',
        justifyContent: 'center',
    }
}

const tokens = {
    parent : { 
        childrenGap: 25, 
        padding: 10 
    },
    child : {
        childrenGap : 50
    },
    button : {
        childrenGap : 10
    }
}  

export default class AleDataModelJSONGenerator extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state =
        {
            isLoading       : true,
            data            : [],
            dmLoading       : true,
            domainList      : [],
            json            : [],

            dmmask          : "",
            error_text      : ""
        }

        this.componentDidMount      = this.componentDidMount.bind(this);
        this.getMask                = this.getMask.bind(this);
        this.textOnChange           = this.textOnChange.bind(this);
        this.submitForm             = this.submitForm.bind(this);
    }

    componentDidMount ()
    {
        this.getMask();
        this.getJSON();
    }

    componentDidUpdate(oldProps) 
    {
        const newProps = this.props

        if(oldProps.curr_node_dn !== newProps.curr_node_dn || oldProps.xauserrole !== newProps.xauserrole) 
        {
            this.getMask();
            this.getJSON();
        }
    }

    textOnChange(e)
    {
        let name = e.target.name;
        let value = e.target.value;

        this.setState({[name]: value});
    }

    submitForm()
    {
        var mask = "";
        
        try{
            mask = JSON.stringify(JSON.parse(this.state.dmmask));
        }
        catch
        {
            this.setState({ error_text: "Invalid json"});
            return;
        }

        var data = {token : sessionStorage.getItem("token")};
        var api = "";

        if(this.state.data.id !== 0)
        {
            // MASK ALREADY EXISTS - CALL UPDATE DMMASK
            api       = "UpdateDMMask/";
            data.id   = this.state.data.id;
            data.mask = mask;
        }
        else
        {
            // MASK DOESN'T EXIST - CALL CREATE DMMASK
            api                = "CreateDMMask/";
            data.dn            = this.props.curr_node_dn;
            data.mask          = mask;
            data.XaUserRole_ID = this.props.xauserrole;
        }

        var c1 = Ale.Api.Call(this.props.service+"/DataModel/"+api,data);

        let self=this;

        c1.then(function(r) 
        {
            if (Ale.Api.ErrorCheck(r))
            {
                self.setState({ error_text:r.error + ": " + r.description, successApiCall: ""});
            }
            else
            {
                self.setState({error_text:""});
                self.getMask();
                self.getJSON();
            }

        }).catch(function(e)
        {

        });
    }

    getJSON()
    {
        var data = {
            token         : sessionStorage.getItem("token"),
            id            : this.props.curr_node_id,
            XaUserRole_ID : this.props.xauserrole
        };

        var c1 = Ale.Api.Call(this.props.service+"/DataModel/GenerateJSON/",data);
        let self = this;

        c1.then(function(r) 
        {
            if (Ale.Api.ErrorCheck(r))
            {
                self.setState({json : ""});
            }
            else
            {
                self.setState({json : r.result});
            }

        }).catch(function(e)
        {
            //Ale.Ui.Loader.Hide();
        });
    }

    render()
    {

        return(

            <>
                <Stack tokens={tokens.parent}>
                    <Separator>{this.state.formTitle}</Separator>
                    <TextField
                        name = 'dmmask'
                        label={Ale.Label.Get("L_M_MASK")}
                        multiline rows={20}
                        value={this.state.dmmask}
                        onChange={this.textOnChange}
                    />
                </Stack>

                <Stack horizontal tokens={tokens.parent} >
                    <Stack grow={11} className={style.stackitem}>
                        { this.state.error_text !== "" &&
                            <MessageBar messageBarType={MessageBarType.error} isMultiline={false} dismissButtonAriaLabel="Close">
                                {this.state.error_text}
                            </MessageBar>
                        }
                    </Stack>
                    <Stack grow={1} className={style.stackbutton}>
                        <PrimaryButton
                            data-automation-id="SubmitButton"
                            text={Ale.Label.Get("L_DATA_MODEL_SUBMIT")}
                            onClick={this.submitForm}
                            allowDisabledFocus={true}
                            style={{'maxWidth': '80px'}}
                        />
                    </Stack>
                </Stack>

                <Stack tokens={tokens.parent}>
                    <pre className="ale-color-text">{JSON.stringify(this.state.json,null,4)}</pre>
                </Stack>
            </>
        );
    }

    getMask()
    {
        var data = {
            token         : sessionStorage.getItem("token"),
            XaUserRole_ID : this.props.xauserrole,
            dn            : this.props.curr_node_dn
        };

        var c1 = Ale.Api.Call(this.props.service+"/DataModel/ReadDMMask/",data);
        let self = this;
        
        c1.then(function(r) 
        {
            Ale.Api.ErrorHandle(r);;

            self.setState({
                data            : r.result,
                dmmask          : JSON.stringify(r.result.mask,null,4),
                isLoading       : false
            });

        }).catch(function(e)
        {
            //Ale.Ui.Loader.Hide();
        });
    }

}