import React, {Component}      from 'react';
import {Router, Switch, Route} from 'react-router';
import { createBrowserHistory } from 'history'


import App                    from '../App'
import AleLogin               from './ale-login'
import AleDashboard           from './ale-dashboard'
import AleDataModelTree       from './ale-datamodel-tree'
import AleApiList             from './ale-api-list'
import AleApiForm             from './ale-api-form'
import AleDataModelTreeDetail from './ale-datamodel-tree-detail';
import AleRoleList            from './ale-role-list'
import AleRoleForm            from './ale-role-form'
import AleUserList            from './ale-user-list'
import AleUserForm            from './ale-user-form'
import AleRbac                from './ale-rbac'
import AleJsonGeneratorTree   from './ale-jsongenerator-tree';
import AleJsonGeneratorDetail from './ale-jsongenerator-detail';
import AleDataModelMaskTree   from './ale-datamodelmask-tree';
import AleDataModelMaskDetail from './ale-datamodelmask-detail';


class AppRoute extends Component
{    
    render() 
    {
        console.log("ToDo:: CHECK TOKEN")

        const history = createBrowserHistory();
        return (
            <Router history={history}>
                <Switch>
                    <Route exact path="/"                component={App} />
                    <Route path="/login"                 component={AleLogin}/>
                    <Route path="/dashboard"             component={AleDashboard}/>
                    <Route path="/datamodel-tree"        component={AleDataModelTree}/>
                    <Route path="/datamodel-tree-detail" component={AleDataModelTreeDetail}/>
                    <Route path="/josngenerator-tree"    component={AleJsonGeneratorTree}/>
                    <Route path="/josngenerator-detail"  component={AleJsonGeneratorDetail}/>
                    <Route path="/datamodelmask-tree"    component={AleDataModelMaskTree}/>
                    <Route path="/datamodelmask-detail"  component={AleDataModelMaskDetail}/>
                    <Route path="/api-list"              component={AleApiList}/>
                    <Route path="/api-form"              component={AleApiForm}/>
                    <Route path="/role-list"             component={AleRoleList}/>
                    <Route path="/role-form"             component={AleRoleForm}/>
                    <Route path="/user-list"             component={AleUserList}/>
                    <Route path="/user-form"             component={AleUserForm}/>
                    <Route path="/rbac"                  component={AleRbac}/>
                </Switch>
            </Router>
        );
    }
}

export default AppRoute;