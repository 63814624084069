import React from 'react';
import Ale   from '../ale-sdk/ale-mobile'

import { Text, Stack,Separator } from 'office-ui-fabric-react';

const style = {
    stackitem : {
        height: 55,
        display: 'flex',
        alignItems: 'left',
        justifyContent: 'center',
//        background: DefaultPalette.themePrimary
    },
    stackbutton : {
        height: 55,
        display: 'flex',
        alignItems: 'right',
        justifyContent: 'center',
//        background: DefaultPalette.themePrimary
    }
}

const tokens = {
    parent : { 
        childrenGap: 25, 
        padding: 10 
    },
    child : {
        childrenGap : 50
    },
    button : {
        childrenGap : 10
    }
}  

export default class AleDataModelJSONGenerator extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state =
        {
            viewId          : this.props.curr_node_id,
            isLoading       : true,
            data            : [],
            dmLoading       : true,
            domainList      : [],
            
        }

        this.componentDidMount      = this.componentDidMount.bind(this);
        this.getJSON                = this.getJSON.bind(this);
    }

    componentDidMount ()
    {
        this.getJSON();
    }

    componentDidUpdate(oldProps) 
    {
        const newProps = this.props
        
        if(oldProps.curr_node_id !== newProps.curr_node_id) 
        {
            this.getJSON();
        }
    }

    render()
    {

        return(

            <Stack tokens={tokens.parent}>
                <Separator>{this.state.formTitle}</Separator>
                             
                <pre className="ale-color-text">{JSON.stringify(this.state.data,null,4)}</pre>
            </Stack>
        );
    }

    getJSON()
    {
        var data = {
            token    : sessionStorage.getItem("token"),
            id       : this.props.curr_node_id
        };

        var c1 = Ale.Api.Call(this.props.service+"/DataModel/GenerateJSON/",data);
        let self = this;
        
        c1.then(function(r) 
        {
            Ale.Api.ErrorHandle(r);;

            self.setState({
                data            : r.result,
                isLoading       : false
            });

        }).catch(function(e)
        {
            //Ale.Ui.Loader.Hide();
        });
    }

}