import React from 'react';
import { Redirect } from 'react-router'

import {Icon} from 'office-ui-fabric-react';

import Ale   from '../ale-sdk/ale-mobile'

export default class AleNavBar extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state =
        {
            clickLogout:false
        }

        this.logout     = this.logout.bind(this);

    }

    logout()
    {
        sessionStorage.removeItem("token");
        this.setState({clickLogout:true})
    }

    render() 
    {
        return (
            
            <div className="navbar mt-0 pt-0 mb-0 pb-0" style={{ width: "100%" }}>

                <div className="float-left">logo</div>

                <div className="float-right">
                    <div onClick={this.logout} style={{ cursor: "pointer" }}>
                        <Icon iconName="Logout" />
                    </div>
                </div>
                


            { this.state.clickLogout === true &&
                <Redirect to="/" push />
            }
            
            </div>
        )
    }
}