import React from 'react';

import { Spinner, SpinnerSize, TextField, ChoiceGroup, DetailsList, SelectionMode, Checkbox} from 'office-ui-fabric-react';

import Ale         from '../ale-sdk/ale-mobile'
/*
service,
obj
evt
description
status
example
*/
export default class AleRBAC extends React.Component
{
    constructor(props)
    {
        super(props)

        this.changeChoiceGroup      = this.changeChoiceGroup.bind(this);
        this.onColumnClick          = this.onColumnClick.bind(this);
        this.tickCheckBox           = this.tickCheckBox.bind(this);
        this.onChangeText           = this.onChangeText.bind(this);

        this.state =
        {
            roleListLoading   : true,
            roleList          : [],
            chosenRole        : 0,
            apiRoleMapLoading : false,
            apiRoleMap        : [],
            apiRoleMap_copy   : [],

            columns           : [
                { 
                    key          : 'allowed',
                    name         : Ale.Label.Get("L_STATUS"),
                    fieldName    : 'allowed',
                    minWidth     : 60,
                    maxWidth     : 60,
                    isResizable  : true,
                    onRender     : (item) => {
                        return ( <Checkbox
                            onChange={this.tickCheckBox}
                            checked={item.assigned}
                            id = {'checkbox-'+item.key}
                    />)}
                },
                {
                    key          : 'service',
                    name         : Ale.Label.Get("L_SERVICE"),
                    fieldName    : 'service',
                    minWidth     : 75,
                    maxWidth     : 150,
                    onColumnClick: this.onColumnClick,
                    isResizable  : true
                },
                {
                    key          : 'obj',
                    name         : Ale.Label.Get("L_OBJ"),
                    fieldName    : 'obj',
                    minWidth     : 75,
                    maxWidth     : 150,
                    onColumnClick: this.onColumnClick,
                    isResizable  : true
                },
                {
                    key          : 'evt',
                    name         : Ale.Label.Get("L_EVT"),
                    fieldName    : 'evt',
                    minWidth     : 75,
                    maxWidth     : 150,
                    onColumnClick: this.onColumnClick,
                    isResizable  : true
                }
            ]
        }
    }

    componentDidMount()
    {
        this.getUserRoleList();
    }

    getUserRoleList()
    {
        var data = {
            token    : sessionStorage.getItem("token"),
            status   : "1"
        };
        var c1 = Ale.Api.Call("Gate/Rbac/RoleList/",data);

        let self = this;
        
        c1.then(function(r) 
        {
            Ale.Api.ErrorHandle(r);

            self.setState({
                roleListLoading : false,
                roleList        : r
            });

        }).catch(function(e)
        {
            //Ale.Ui.Loader.Hide();
        });
    }

    getApiRoleMap(userRoleId)
    {
        var data = {
            token         : sessionStorage.getItem("token"),
            XaUserRole_ID : userRoleId
        };
        var c1 = Ale.Api.Call("Gate/Rbac/ListApiByRole/",data);

        let self = this;
        
        c1.then(function(r) 
        {
            Ale.Api.ErrorHandle(r);

            self.setState({
                apiRoleMapLoading : false,
                apiRoleMap        : r.result,
                apiRoleMap_copy   : r.result,
                chosenRole        : userRoleId
            });

        }).catch(function(e)
        {
            //Ale.Ui.Loader.Hide();
        });
    }

    onChangeText (ev, text) 
    {
        text     = text.toLowerCase();
        this.setState({
            apiRoleMap: text ? this.state.apiRoleMap_copy.filter(i => i.evt.toLowerCase().indexOf(text) > -1) : this.state.apiRoleMap_copy
        });

    };

    onColumnClick (ev, column)
    {
        const { columns, apiRoleMap } = this.state;
        const newColumns = columns.slice();
        const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0];

        newColumns.forEach((newCol) => {
            if (newCol === currColumn)
            {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            }
            else
            {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });

        const newapiRoleMap = _copyAndSort(apiRoleMap, currColumn.fieldName, currColumn.isSortedDescending);
        this.setState({
            columns: newColumns,
            apiRoleMap: newapiRoleMap
        });


        function _copyAndSort(items, columnKey, isSortedDescending)
        {
            const key = columnKey;
                return items.slice(0).sort((a, b) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
        }
    };

    changeChoiceGroup(e, option)
    {
        let userRoleID = option.key.split('-')[1];
        this.setState({apiRoleMapLoading : true})
        this.getApiRoleMap(userRoleID);
    }

    getUserRolesChoices()
    {
        let userRoles=[];

        for(let i=0; i < this.state.roleList.result.length; i++)
        {
            let name = this.state.roleList.result[i].name;
            let id = this.state.roleList.result[i].id;
            userRoles.push({key: name+"-"+id, text: name});
        }

        return userRoles;
    }

    getApiUserRoleMap()
    {
        let apiUserRoleMap=[];

        console.log(this.state.apiRoleMap);
        if(this.state.apiRoleMap === undefined)
        {
            return [];
        }

        for(let i=0; i < this.state.apiRoleMap.length; i++)
        {
            
            let service = this.state.apiRoleMap[i].service;
            let object  = this.state.apiRoleMap[i].obj;
            let event   = this.state.apiRoleMap[i].evt;
            let id      = this.state.apiRoleMap[i].service_ID;
            let status  = this.state.apiRoleMap[i].serviceStatus === "1" ? "Active": "Deactivated";
            let assigned = this.state.apiRoleMap[i].assigned === "1" ? true : false
            apiUserRoleMap.push({key: id, service: service, obj: object, evt: event, status: status, assigned:assigned});
        }

        return apiUserRoleMap;
    }

    tickCheckBox(e)
    {
        let key = e.target.id.split('-')[1];

        let found = this.state.apiRoleMap.filter(
            function(property) {
                return property.service_ID === key
            }
        );

        var data = {
            token         : sessionStorage.getItem("token"),
            XaUserRole_ID : this.state.chosenRole,
	        XaService_ID  : found[0].service_ID
        };
        var c1 = "";
        
        if(found[0].assigned==="0")
        {
            c1 = Ale.Api.Call("Gate/Rbac/MapUserRoleApi/",data);
        }
        else
        {
            c1 = Ale.Api.Call("Gate/Rbac/RevokeApiAccess/",data);
        }

        let self = this;
        
        c1.then(function(r) 
        {
            Ale.Api.ErrorHandle(r);

            found[0].assigned = found[0].assigned === "1" ? "0" : "1";
            self.setState({abc:"1"})

        }).catch(function(e)
        {
            //Ale.Ui.Loader.Hide();
        });
    }

    render() 
    {
        
        let userRoles      = [];
        let apiRoleMap     = [];

        if(!this.state.roleListLoading)
        {
            userRoles=this.getUserRolesChoices();
        }

        if(!this.state.apiRoleMapLoading)
        {
            apiRoleMap = this.getApiUserRoleMap();
        }

        return (

            <>
                <div id="ale-sub-navbar" className="flex-row sticky-top d-flex ale-sub-navbar">

                    
                </div>

                 <div id="ale-section" className="flex-row ale-section" >

                    <div className="col-md-2 float-left ale-border-right h-100 p-0 ale-background-darker" style={{overflowY:"scroll"}}>

                        { this.state.roleListLoading === false &&
                            <ChoiceGroup
                                className="defaultChoiceGroup"
                                options={userRoles}
                                onChange={this.changeChoiceGroup}
                                compact={true}
                                label={Ale.Label.Get("L_RBAC_USER_ROLE_CHOICE")}
                            />
                        }
                        { this.state.roleListLoading === true &&
                            <Spinner size={SpinnerSize.large}  />
                        }

                    </div>

                
                    <div className="col-md-10 float-right h-100 ale-background-darker" style={{overflowY:"scroll", marginRight:"-5px"}}>

                        { this.state.apiRoleMapLoading === false && 
                            <>
                                <TextField
                                    placeholder={Ale.Label.Get("L_FILTER_BY_EVENT")}
                                    onChange={this.onChangeText}
                                    styles={{ root: { maxWidth: '300px' } }}
                                    underlined
                                />
                                <DetailsList
                                    items={apiRoleMap}
                                    compact={true}
                                    columns={this.state.columns}
                                    selectionMode={SelectionMode.none}
                                />
                                
                            </>
                        }
                        { this.state.apiRoleMapLoading === true &&
                            <Spinner size={SpinnerSize.large}  />
                        }

                    </div>
                </div>
            </>
        )
    }
}