
const label={

    //GENERAL
    "L_APP_TITLE": "AleMicro3 Dashboard",


    //MENU
    "L_M_DASHBOARD" : "Dashboard",
    "L_M_DATA_MODEL": "Data Model",
    "L_M_TREE"      : "Data Model Tree",
    "L_M_TYPES"     : "Types",
    "L_M_USERS_AND_ROLES" : "Users & Roles",
    "L_M_USERS"     : "Users",
    "L_M_ROLES"     : "Roles",
    "L_M_SERVICES_AND_APIS" : "Services & Apis",
    "L_M_SERVICES" : "Services",
    "L_M_APIS"     : "Apis",
    "L_M_RBAC"     : "Rbac",
    "L_M_DOCUMENTATION" : "Documentation",
    "L_M_JSON_GEN" : "Json generator",
    "L_M_API_ADD"  : "Add new Api",
    "L_M_MASK"     : "Data Model mask",

    /* LABEL */
    "L_ID"              : "#ID",
    "L_EMAIL"           : "E-Mail",
    "L_PASSWORD"        : "Password",
    "L_NEW_PWD"         : "New password",
    "L_RPT_PWD"         : "Repeat password",
    "L_OTP"             : "Security code (otp)",
    "L_LOGIN"           : "Login",
    "L_SIGN_IN"         : "Sign In !!",
    "L_PWD_CHNG_TITLE"  : "Change password",
    "L_PWD_NOT_MATCH"   : "Passwords do not match",
    "L_EMPTY_FIELD"     : "Please populate all required fields",
    "L_OK"              : "OK",
    "L_CANCEL"          : "Cancel",
    "L_SEARCH"          : "Search",
    "L_STATUS"          : "Status",
    "L_UPDATED"         : "Updated",
    "L_NAME"            : "Name",
    "L_SURNAME"         : "Surname",
    "L_USERNAME"        : "Username",
    "L_USER_OU"         : "Organisation unit",
    "L_ROLE_NAME"       : "Role name",
    "L_ADD_ROLE"        : "Add user role",
    "L_FILTER_BY_ROLE_NAME" : "Filter by role name",
    "L_TFA_ENABLED"     : "2FA enabled",
    "L_ADD_USER"        : "Add user",
    "L_EDIT_USER"       : "Update user details",
    "L_ADD_USER_ROLE"   : "Add user role",
    "L_EDIT_USER_ROLE"  : "Update user role",
    "L_FILTER_BY_USERNAME" : "Filter by username",
    "L_SERVICE"         : "Service",
    "L_SERVICE_NAME"    : "Service name",
    "L_OBJ"             : "Object",
    "L_OBJ_NAME"        : "Object name",
    "L_EVT"             : "Event",
    "L_EVT_NAME"        : "Event name",
    "L_DESCRIPTION"     : "Description",
    "L_EXAMPLE"         : "Example",
    "L_FILTER_BY_SERVICE": "Filter by service",
    "L_FILTER_BY_EVENT" : "Filter by event",
    "L_ADD_API"         : "Add Api",
    "L_EDIT_API"        : "Update Api Details",
    "L_MODIFY"          : "Modify",
    "L_ENABLE_DISABLE"  : "Enable/Disable",
    "L_ENABLE_DISABLE_2FA" : "Enable/Disable 2FA",
    "L_RESET_TFA"       : "Reset 2FA",
    "L_USED_TFA"        : "Used 2FA",
    "L_ENABLE_DISABLE_LOGIN" : "Enable/Disable login",
    "L_LOGIN"           : "Login enabled",
    "L_LOGIN_ENABLED" : "Login enabled",
    "L_LOGIN_DISABLED" : "Login disabled",


    "L_QR_INSTR_HEAD"  : "Add second step authentication",
    "L_QR_INSTR_BODY1" : " requires two factor authentication. Please follow below steps:",
    "L_QR_INSTR_BODY2" : "1. Install the authentication app on you phone",
    "L_QR_INSTR_BODY3" : "2. Using app scan the QR Code",

    "L_LIST_DATA_MODEL"            : "Data model tree",
    "L_DATA_MODEL_IN_CREATE"       : "In create",
    "L_DATA_MODEL_IN_SEARCH"       : "In Search",
    "L_DATA_MODEL_IN_UPDATE"       : "In Update",
    "L_DATA_MODEL_IS_COLUMN"       : "Is Column",
    "L_DATA_MODEL_REQUIRED"        : "Required",
    "L_DATA_MODEL_TABLE_NAME"      : "Data model table name",
    "L_VALUE_TABLE_NAME"           : "Table with values",
    "L_DATA_MODEL_NAME"            : "Name",
    "L_DATA_MODEL_IN_CREATE_ORDER" : "Order in create",
    "L_DATA_MODEL_MAX_VALUE"       : "Max value",
    "L_DATA_MODEL_MIN_VALUE"       : "Min value",
    "L_DATA_MODEL_COLUMN_NAME"     : "Column name",
    "L_DATA_MODEL_SUBMIT"          : "Submit",
    "L_DATA_MODEL_CANCEL"          : "Cancel",
    "L_DOMAIN_LIST"                : "Domain name list",

    "L_DATA_MODEL_ADD_ELEMENT" : "Add element",
    "L_DATA_MODEL_ADD_ROOT"    : "Add root element",
    "L_DATA_MODEL_ADD_CLASS"   : "Add class",
    "L_DATA_MODEL_ADD_ARRAY_OF_STRUCTURE" : "Add array of structure",
    "L_DATA_MODEL_ADD_STRUCTURE" : "Add structure",
    "L_DATA_MODEL_ADD_ARRAY"    : "Add array",
    "L_DATA_MODEL_ADD_FIELD"    : "Add field",
    "L_DATA_MODEL_ADD_DOMAIN"   : "Add domain",
    "L_DATA_MODEL_TREE_SEARCH"  : "Search the tree",
    "L_DATA_MODEL_ELEMENT_ADDED": "Element added successfully",
    "L_DATA_MODEL_ELEMENT_UPDATED": "Element updated successfully",
    "L_DATA_MODEL_EDIT_ITEM"    : "Edit item",
    "L_DATA_MODEL_ADD_ITEM"     : "Add new item",
    "L_DATA_MODEL_SELECT_SERVICE": "Select service",

    /* RBAC LABELS */
    "L_RBAC_USER_ROLE_CHOICE"           : "Pick User Role",

    /* REGEXP LABELS */
    "L_REGEXP"                          : "Regular expression",
    "L_REGEXP_HELP"                     : "Choose regular expression from list",
    "L_REGEXP_KEY"                      : "Key",
    "L_REGEXP_DOMAIN"                   : "Domain",
    "L_REGEXP_NUMBER_INTEGER"           : "Number (positive integer)",
    "L_REGEXP_DATE_YYYYMMDD"            : "Date (YYYYMMDD)",
    "L_REGEXP_STRING_FREE"              : "Free string",
    "L_REGEXP_GEO_TOWN"                 : "Town",
    "L_REGEXP_COORDINATE"               : "Coordinate",
    "L_REGEXP_DATE_YEAR"                : "Date (YYYY)",
    "L_REGEXP_NUMBER_FLOAT"             : "Flat number",
    "L_REGEXP_BOOLEAN_NUMBER"           : "Boolean",
    "L_REGEXP_NAME"                     : "Name",
    "L_REGEXP_EMAIL"                    : "Email",
    "L_REGEXP_STRING_PROPER_NOUN"       : "Proper noun",

    /* TYPE LABELS */
    "L_TYPE"                    : "Type",
    "L_TYPE_ROOT"               : "Root",
    "L_TYPE_CLASS"              : "Class",
    "L_TYPE_STRUCTURE"          : "Structure",
    "L_TYPE_ARRAY_OF_STRUCTURE" : "Array Of Structure",
    "L_TYPE_ARRAY"              : "Array",
    "L_TYPE_FIELD"              : "Field",


    /* REGEXP VIOLATION MESSAGES */
    "P_MESSAGE" : "Il valore inserito per il campo :?1: non e' corretto: :?2:",
    "P_SQMT"    : "puoi inserire un valore compreso tra 10 e 999",
    "P_ROOMS"   : "puoi inserire un valore compreso tra 0 e 99",


    /* GENERIC ERRORS */
    "Error"         : "Oops, something went wrong...",
    "Error-45"      : "The user doesn't exist or the username/password/otp combination is wrong",
    "Error-49"      : "OTP code is not valid",
    "Error-52"      : "the session is expired please login again",
    "Error-205"     : "Missing mandatory field",
    "Error-230"     : "Error input data",
    //"Error-3003"    : "Immobile non esistente o eliminato",
    "Error-59"      : "User not authorised",
    "Error-60"      : "User not authorised",
    "Error-63"      : "The new password can't be same as already existing",
    
    "Error-Generic" : "Oops, something went wrong ... please retry",


    "is_active":
    [
        {"value":"1", "title":"Active"},
        {"value":"0", "title":"Not Active"}
    ]
};


export default label