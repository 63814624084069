//
//'root'
//'class'
//'field'
//'structure'
//'array-of-structure'
import React    from "react";
import ReactDOM from 'react-dom';

import { Spinner, SpinnerSize, TextField, CommandBarButton} from 'office-ui-fabric-react';

import {Treebeard} from 'react-treebeard';
import * as filters from './ale-datamodel-tree-filter';

import AleJSONGenerator from './ale-jsongenerator-detail'

import Ale         from '../ale-sdk/ale-mobile'
    
const style = {
    tree: {
      base: {
        listStyle: 'none',
        backgroundColor: 'transparent',
        margin: 0,
        padding: 0,
        color: 'rgba(255, 255, 255, 0.95)',
        fontFamily: 'Verdana, Geneva, sans-serif !important',
        fontSize: 'small'
      },
      node: {
        base: {
          position: 'relative'
        },
        link: {
          cursor: 'pointer',
          position: 'relative',
          padding: '0px 5px',
          display: 'block'
        },
        activeLink: {
          background: 'rgba(47,47,47,0.5)'
        },
        toggle: {
          base: {
            position: 'relative',
            display: 'inline-block',
            verticalAlign: 'top',
            marginLeft: '-5px',
            height: '24px',
            width: '24px'
          },
          wrapper: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            margin: '-7px 0 0 -7px',
            height: '14px'
          },
          height: 14,
          width: 14,
          arrow: {
            fill: 'rgba(255, 255, 255, 0.25)',
            strokeWidth: 0
          }
        },
        header: {
          base: {
            display: 'inline-block',
            verticalAlign: 'top',
            color: 'rgba(255, 255, 255, 0.95)'
          },
          connector: {
            width: '2px',
            height: '12px',
            borderLeft: 'solid 2px black',
            borderBottom: 'solid 2px black',
            position: 'absolute',
            top: '0px',
            left: '-21px'
          },
          title: {
            lineHeight: '24px',
            verticalAlign: 'middle'
          }
        },
        subtree: {
          listStyle: 'none',
          paddingLeft: '19px'
        },
        loading: {
          color: 'transparent'
        }
      }
    }
  }

export default class AleJsonGeneratorTree extends React.Component {

    constructor(props)
    {
        super(props);

        this.componentDidMount  = this.componentDidMount.bind(this);
        this.getData            = this.getData.bind(this);
        this.onToggle            = this.onToggle.bind(this);

        this.state =
        {
            isCompactMode : true,
            loading    : false,
            error      : false,
            error_code : 0,
            tree : [],
            data:{},
            data_copy:{},
            curr_node_id:0,
            curr_node_type: "",
            service : ""
        };
    }

    componentDidMount ()
    {
        console.log("componentDidMount")
        this.getData("");
    }

    getData(service)
    {
        if(service=="")
        {
          return;
        }

        this.setState({ loading: true, button_pressed : "", service: service })

        var params = {
            token    : sessionStorage.getItem("token"),
            subset   : "1"
        };

        var c1 = Ale.Api.Call(service+"/DataModel/Tree/",params);
        let self = this;
        c1.then(function(r)
        {
            if (Ale.Api.ErrorCheck(r))
            {
                self.setState({ loading: false })
                self.setState({ error: true,  error_code:r.error})
            }
            else
            {
                self.setState({ loading: false})
                self.setState({ data: r.result[0],data_copy:r.result[0] })
            }

        }).catch(function(e)
        {

        });
    }

    onToggle(node, toggled){
        const {cursor, data} = this.state;
        if (cursor) {
            this.setState(() => ({cursor, active: false}));
        }
        node.active = true;
        if (node.children) { 
            node.toggled = toggled; 
        }
        this.setState(() => ({cursor: node, data: Object.assign({}, data)}));

        this.setState({curr_node_id:node.id, curr_node_type: node.type, button_pressed:""})
    }

    onFilterMouseUp({target: {value}}) {

        console.log(value)
        const {data_copy} = this.state;
        
        //coping original data

        const filter = value.trim();
        
        if (!filter) {
            this.setState({data:data_copy});
        }

        let filtered = filters.filterTree(data_copy, filter);
        console.log(filtered);
        
        filtered = filters.expandFilteredNodes(filtered, filter);
        this.setState(() => ({data: filtered}));
    }

    render() 
    {
        const { data } = this.state;

        let cmdbartext = Ale.Label.Get("L_DATA_MODEL_SELECT_SERVICE");

        if(this.state.service!=="")
        {
            cmdbartext = this.state.service;
        }

    return (
        <>
            <div id="ale-sub-navbar" className="flex-row sticky-top d-flex ale-sub-navbar">

                <CommandBarButton
                    data-automation-id="service"
                    text={cmdbartext}
                    style={{'width': '150px'}}
                    menuProps={{
                        items : [
                            { key: 'Gate', text: 'Gate', onClick:()=>{this.setState({service : 'Gate'}); this.getData('Gate')} },
                            { key: 'RealEstate', text: 'RealEstate', onClick:()=>{this.setState({service : 'RealEstate'}); this.getData('RealEstate')} },
                            { key: 'Calendar', text: 'Calendar', onClick:()=>{this.setState({service : 'Calendar'}); this.getData('Calendar')} }
                        ]
                    }}
                />
            </div>

            <div id="ale-section" className="flex-row ale-section" >

                <div className="col-md-4 float-left ale-border-right h-100 p-0 ale-background-darker" style={{overflowY:"scroll"}}>

                    <TextField placeholder={Ale.Label.Get("L_DATA_MODEL_TREE_SEARCH")} underlined iconProps={{ iconName: 'Search' }} onKeyUp={this.onFilterMouseUp.bind(this)} />

                    <br/>

                    { this.state.loading === false &&
                        <Treebeard
                          data={data}
                          onToggle={this.onToggle}
                          style={style}
                        />
                    }
                    { this.state.loading === true &&

                        <Spinner size={SpinnerSize.large}  />
                    }

                </div>
        
                <div className="col-md-8 float-right h-100 ale-background-darker" style={{overflowY:"scroll", marginRight:"-5px"}}>

                    {this.state.curr_node_id !==0 &&
                        <AleJSONGenerator curr_node_id={this.state.curr_node_id} service={this.state.service} />
                    }

                </div>
            </div>
        </>
           
    );
  }
}