//
//'root'
//'class'
//'field'
//'structure'
//'array-of-structure'
import React    from "react";
import ReactDOM from 'react-dom';

import { Spinner, SpinnerSize, TextField, CommandBarButton, Dropdown} from 'office-ui-fabric-react';

import {Treebeard} from 'react-treebeard';
import * as filters from './ale-datamodel-tree-filter';

import AleDataModelTreeDetail from './ale-datamodel-tree-detail'

import Ale         from '../ale-sdk/ale-mobile'
    
const style = {
    tree: {
      base: {
        listStyle: 'none',
        backgroundColor: 'transparent',
        margin: 0,
        padding: 0,
        color: 'rgba(255, 255, 255, 0.95)',
        fontFamily: 'Verdana, Geneva, sans-serif !important',
        fontSize: 'small'
      },
      node: {
        base: {
          position: 'relative'
        },
        link: {
          cursor: 'pointer',
          position: 'relative',
          padding: '0px 5px',
          display: 'block'
        },
        activeLink: {
          background: 'rgba(47,47,47,0.5)'
        },
        toggle: {
          base: {
            position: 'relative',
            display: 'inline-block',
            verticalAlign: 'top',
            marginLeft: '-5px',
            height: '24px',
            width: '24px'
          },
          wrapper: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            margin: '-7px 0 0 -7px',
            height: '14px'
          },
          height: 14,
          width: 14,
          arrow: {
            fill: 'rgba(255, 255, 255, 0.25)',
            strokeWidth: 0
          }
        },
        header: {
          base: {
            display: 'inline-block',
            verticalAlign: 'top',
            color: 'rgba(255, 255, 255, 0.95)'
          },
          connector: {
            width: '2px',
            height: '12px',
            borderLeft: 'solid 2px black',
            borderBottom: 'solid 2px black',
            position: 'absolute',
            top: '0px',
            left: '-21px'
          },
          title: {
            lineHeight: '24px',
            verticalAlign: 'middle'
          }
        },
        subtree: {
          listStyle: 'none',
          paddingLeft: '19px'
        },
        loading: {
          color: 'transparent'
        }
      }
    }
  }

export default class AleDataModelTree extends React.Component {

    constructor(props)
    {
        super(props);

        this.componentDidMount  = this.componentDidMount.bind(this);
        this.getData            = this.getData.bind(this);
        //this.getTree            = this.getTree.bind(this);
        this.onToggle            = this.onToggle.bind(this);
        this.formCancelled      = this.formCancelled.bind(this);
        this.refreshData        = this.refreshData.bind(this);

        //this.onItemClick        = this.onItemClick.bind(this);

        //console.log(JSON.stringify(this._items));
        this.state =
        {
            isCompactMode : true,
            loading    : false,
            error      : false,
            error_code : 0,
            tree : [],
            data:{},
            data_copy:{},
            curr_node_id:0,
            curr_node_type: "",
            button_pressed: "",
            service : ""
        };
    }

    componentDidMount ()
    {
        console.log("componentDidMount")
        this.getData("");
    }

    formCancelled()
    {
        this.setState({ button_pressed : "" })
    }

    refreshData()
    {

      console.log("refreshData: " + this.state.service);
      //console.log("refreshData: " + service);
      this.getData(this.state.service);
    }

    getData(service)
    {
        if(service=="")
        {
          return;
        }

        this.setState({ loading: true, button_pressed : "", service: service })

        var params = {
            token    : sessionStorage.getItem("token")
        };

        var c1 = Ale.Api.Call(service+"/DataModel/Tree/",params);
        let self = this;
        c1.then(function(r)
        {
            if (Ale.Api.ErrorCheck(r))
            {
                self.setState({ loading: false })
                self.setState({ error: true,  error_code:r.error})
            }
            else
            {
                self.setState({ loading: false})
                self.setState({ data: r.result[0],data_copy:r.result[0] })
            }

        }).catch(function(e)
        {

        });
    }

    onToggle(node, toggled){
        const {cursor, data} = this.state;
        if (cursor) {
            this.setState(() => ({cursor, active: false}));
        }
        node.active = true;
        if (node.children) { 
            node.toggled = toggled; 
        }
        this.setState(() => ({cursor: node, data: Object.assign({}, data)}));

        this.setState({curr_node_id:node.id, curr_node_type: node.type, button_pressed:""})
    }

    onFilterMouseUp({target: {value}}) {

        console.log(value)
        const {data_copy} = this.state;
        
        //coping original data

        const filter = value.trim();
        
        if (!filter) {
            this.setState({data:data_copy});
        }

        let filtered = filters.filterTree(data_copy, filter);
        console.log(filtered);
        
        filtered = filters.expandFilteredNodes(filtered, filter);
        this.setState(() => ({data: filtered}));
    }

    getCommandBarButtonItems()
    {
      let items = [];

      if(this.state.curr_node_type==="root")
      {
        items.push({ key:  'addClass', text:Ale.Label.Get("L_DATA_MODEL_ADD_CLASS"), onClick:()=>this.setState({button_pressed : 'Add-Class'})});
      }
      else if (this.state.curr_node_type==="class")
      {
        items.push({ key:  'addClass', text:Ale.Label.Get("L_DATA_MODEL_ADD_CLASS"), onClick:()=>this.setState({button_pressed : 'Add-Class'})});
        items.push({ key:  'addArrayOfStructure', text:Ale.Label.Get("L_DATA_MODEL_ADD_ARRAY_OF_STRUCTURE"), onClick:()=>this.setState({button_pressed : 'Add-Array-Of-Structure'})});
        items.push({ key:  'addStructure', text:Ale.Label.Get("L_DATA_MODEL_ADD_STRUCTURE"), onClick:()=>this.setState({button_pressed : 'Add-Structure'})});
        items.push({ key:  'addArray', text:Ale.Label.Get("L_DATA_MODEL_ADD_ARRAY"), onClick:()=>this.setState({button_pressed : 'Add-Array'})});
        items.push({ key:  'addField', text:Ale.Label.Get("L_DATA_MODEL_ADD_FIELD"), onClick:()=>this.setState({button_pressed : 'Add-Field'})});
        items.push({ key:  'addDomain', text:Ale.Label.Get("L_DATA_MODEL_ADD_DOMAIN"), onClick:()=>this.setState({button_pressed : 'Add-Domain'})});
      } 
      else if(this.state.curr_node_type==="array-of-structure" || this.state.curr_node_type==="structure")
      {
        items.push({ key:  'addArrayOfStructure', text:Ale.Label.Get("L_DATA_MODEL_ADD_ARRAY_OF_STRUCTURE"), onClick:()=>this.setState({button_pressed : 'Add-Array-Of-Structure'})});
        items.push({ key:  'addStructure', text:Ale.Label.Get("L_DATA_MODEL_ADD_STRUCTURE"), onClick:()=>this.setState({button_pressed : 'Add-Structure'})});
        items.push({ key:  'addArray', text:Ale.Label.Get("L_DATA_MODEL_ADD_ARRAY"), onClick:()=>this.setState({button_pressed : 'Add-Array'})});
        items.push({ key:  'addField', text:Ale.Label.Get("L_DATA_MODEL_ADD_FIELD"), onClick:()=>this.setState({button_pressed : 'Add-Field'})});
        items.push({ key:  'addDomain', text:Ale.Label.Get("L_DATA_MODEL_ADD_DOMAIN"), onClick:()=>this.setState({button_pressed : 'Add-Domain'})});
      }

      return items;
    }

    render() 
    {
        const { data } = this.state;

        let items = this.getCommandBarButtonItems();

        let cmdbartext = Ale.Label.Get("L_DATA_MODEL_SELECT_SERVICE");

        if(this.state.service!=="")
        {
            cmdbartext = this.state.service;
        }

    return (

/*
        "L_DATA_MODEL_ADD_ELEMENT" : "Add element",
        "L_DATA_MODEL_ADD_ROOT"    : "Add root element",
        "L_DATA_MODEL_ADD_CLASS"   : "Add class",
        "L_DATA_MODEL_ADD_ARRAY_OF_STRUCTURE" : "Add array of structure",
        "L_DATA_MODEL_ADD_STRUCTURE" : "Add structure",
        "L_DATA_MODEL_ADD_FIELD"    : "Add field",
        { key: 'Gate', text: 'Gate', onClick:()=>this.setState({service : 'Gate'}) },
                        { key: 'RealEstate', text: 'RealEstate', onClick:()=>this.setState({service : 'RealEstate'}) }
  */  



    <>
        <div id="ale-sub-navbar" className="flex-row sticky-top d-flex ale-sub-navbar">

            <CommandBarButton
                data-automation-id="service"
                text={cmdbartext}
                style={{'width': '150px'}}
                menuProps={{
                    items : [
                        { key: 'Gate', text: 'Gate', onClick:()=>{this.setState({service : 'Gate'}); this.getData('Gate')} },
                        { key: 'RealEstate', text: 'RealEstate', onClick:()=>{this.setState({service : 'RealEstate'}); this.getData('RealEstate')} },
                        { key: 'Calendar', text: 'Calendar', onClick:()=>{this.setState({service : 'Calendar'}); this.getData('Calendar')} }
                    ]
                }}
            />

            {this.state.curr_node_type!=="" && this.state.curr_node_type!=="array" && this.state.curr_node_type!=="field" &&
            <CommandBarButton
                data-automation-id="test"
                iconProps={{ iconName: 'Add' }}
                text={Ale.Label.Get("L_DATA_MODEL_ADD_ELEMENT")}
                menuProps={{
                    items
                }}
            />
            }
               {/* <CommandBarButton
                   data-automation-id="test2"

                   iconProps={{ iconName: 'Add' }}
                   text="Send Mail"
               /> */}
        </div>

       <div id="ale-section" className="flex-row ale-section" >

            <div className="col-md-4 float-left ale-border-right h-100 p-0 ale-background-darker" style={{overflowY:"scroll"}}>

                <TextField placeholder={Ale.Label.Get("L_DATA_MODEL_TREE_SEARCH")} underlined iconProps={{ iconName: 'Search' }} onKeyUp={this.onFilterMouseUp.bind(this)} />
                
                <br/>

                { this.state.loading === false &&
                    <Treebeard
                    data={data}
                    onToggle={this.onToggle}
                    style={style}
                    />
                }
                { this.state.loading === true &&

                    <Spinner size={SpinnerSize.large}  />
                }

            </div>
        
            <div className="col-md-8 float-right h-100 ale-background-darker" style={{overflowY:"scroll", marginRight:"-5px"}}>

                {this.state.curr_node_id !==0 &&
                    <AleDataModelTreeDetail curr_node_id={this.state.curr_node_id} action={this.state.button_pressed} refreshData={this.refreshData} formCancelled={this.formCancelled} service={this.state.service} />
                }

            </div>
        </div>
    </>
           
    );
  }
}