import React from 'react';

import './ale-components/ale-icon'

import AleLogin     from './ale-components/ale-login'
import AleDashboard from './ale-components/ale-dashboard'

export default class App extends React.Component
{
    constructor(props)
    {
        super(props);

        console.log("Component App")

        this.state =
        {
        }

    }

    componentDidMount ()
    {
        console.log("componentDidMount")
    }

    render()
    {
        return(
            <>
                {
                    sessionStorage.getItem("token") &&
                    <AleDashboard/>
                }
                {
                    !sessionStorage.getItem("token") &&
                    <AleLogin/>
                }

            </>
        )
        /*
        //CHECK ON THE SERVER SIDE
        if(sessionStorage.getItem("token"))
        {
            return (
                <AleDashboard/>
            )
        }
        else
        {
            return (
                <AleLogin/>
            )
        }
        */
    }
}