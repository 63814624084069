//******************************************************************************
//
// Alessandro Mariotti
// alessandromariotti@hotmail.com
//
//******************************************************************************
import axios  from 'axios';
import config from './ale-config'
import label  from './ale-label_en'

var Ale = {};

Ale.isNull = function (field)
{
    console.log("Ale.isNull",field);
    if (typeof field === "object")
    {
        if(field!=null && field!=undefined )
        {
            return false;
        }
        else
        {
            return true;
        }
    }

    if (typeof field === "number" || typeof field === "boolean")
    {
        field = field.toString();
    }
    else if(typeof field === "string")
    {
        field = field.toLowerCase();
    }

    if(field!=="" && field!==undefined && field!==null && field!=="undefined" && field!=="null" && field!=="Null" && !field.includes("undefined") && !field.includes("null") && !field.includes("Null"))
    {
        return false;
    }
    else
    {
        return true;
    }
};

Ale.checkDataType = function (value,type)
{
    console.log("Ale.checkType",value,type);

    let Reg_Exp =[];
    //Reg_Exp["email"]     =/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    Reg_Exp["integer-1"]   = "^[0-9]{1}$";
    Reg_Exp["integer-2"]   = "^[0-9]{2}$"; //^[0-9]|[0-2][0-9]|3[0-6]$

    Reg_Exp["otp"]   = "^[0-9]{0,6}$";

    Reg_Exp["integer-between-1-3"] = "^[1-3]{1}$";

    Reg_Exp["price"]    = "[0-9.]";
    Reg_Exp["zip_code"] = "[0-9][0-9][0-9][0-9][0-9]";
    Reg_Exp["sqmt"]     = "^[0-9]{2,3}$";
    Reg_Exp["rooms"]    = "between(1,25)";



    Reg_Exp["email"]     = "(.*)";
    Reg_Exp["password"]  = "(.*)";
    
    Reg_Exp["address"]   = "(.*)";
    Reg_Exp["latitude"]  = "(.*)";
    Reg_Exp["longitude"] = "(.*)";
    Reg_Exp["radius"]    = "between(0.25,15)";
    Reg_Exp["from_year"] = "^(2014|2015|2016|2017|2018)$";

    if(Ale.isNull(Reg_Exp[type]))
    {
        console.log("Ale.checkType","Type not valid",type);
        return false;
    }

    if(Reg_Exp[type].indexOf("between") !== -1)
    {
        let extreme = Reg_Exp[type].match(/\(([^)]+)\)/)[1].split(',');
        var result = (parseInt(extreme[0])<=value && value<=parseInt(extreme[1])) ? true : false;

        return result;
    }
    else
    {
        return RegExp(Reg_Exp[type]).test(String(value).toLowerCase());
    }
};
/**
 * 
    var t=Ale.Sleep(5000);
    t.then(function() {
        console.log("ciao");

    });
 *
 */

Ale.Sleep =function (ms)
{
    return new Promise(resolve => setTimeout(resolve, ms));
};


//******************************************************************************
//
// DOM FUNCTIONS
//
//******************************************************************************
Ale.Dom = function(){};

/*
Ale.Dom.AddContent = function (selector,content)
{
    $(selector).html(content);
    Ale.Label.Parse(selector);
};
*/

//******************************************************************************
//
// API FUNCTIONS
//
//******************************************************************************
Ale.Api = function(){};


Ale.Api.Get = async function (Url,Data)
{
    console.log(`Ale.Api.Get: Action -> ${Url} - Data -> ${Data}`);

    if(Ale.isNull(Data))
    {
        Data="";
    }

    try
    {
        return axios.get(Url + Data)
        .then(function(response){

             console.log(response.data);
             return response.data;
         })
         .catch(function (error) {
             console.log(error);
             return error.response.data;
         });

    }
    catch (e)
    {
        console.log("Ale.Api.Get: ERROR -> "+ Url + " Code: " + e.code + " Message: " + e.message);
        throw new Error("Error-Ajax");
    }
  }


/**
 * Funzione per effettuare una chiamata alle API di AleMicro2
 *
 * ESEMPIO:
 *
 *      var p = Ale.Api.Call("Api",{"data":"data"} );
 *
 *      p.then(function(result) {
 *          console.log(JSON.stringify(result));
 *      });
 */
Ale.Api.Call = async function (Action,Data)
{
    console.log("Ale.Api.Call: Action  -> " + Action);
    console.log("Ale.Api.Call: Data    -> " + JSON.stringify(Data));

    //var t0 = performance.now();
    try
    {
        return axios.post(config.MyApp.ApiUrl+Action, Data)
        .then(function(response){

           // if(!Ale.CheckNull(response.headers.location==""))
            //{
              //  console.log("redirecting",JSON.stringify(response.headers.location));
                //window.location = response.headers.location;
            //}
            //else
            //{
            console.log(response.data);
            
            return response.data;
            //}
        })
        .catch(function (error) {
            console.log(error);
            Ale.Ui.Loader.Hide();
            return error.response.data;
        });
    }
    catch (e)
    {
        Ale.Ui.Loader.Hide();
        console.log("Ale.Api.Call: ERROR -> "+ Action + " Code: " + e.code + " Message: " + e.message);
        throw new Error("Error-Ajax");
    }

//    var t1 = performance.now();
  //  var dt = (t1 - t0);

    //console.log("Ale.Api.Call: Ex Time -> " + dt);
}

/**
 * Gestisce l'errore nelle risposte API visualizzando il messaggio
 * contenuto nel file label e lanciando una eccezione
*/
Ale.Api.ErrorHandle = function (result)
{
    var ErrorNumber="";
    var ErrorDescription="";

    //$.each( result, function( key, val ) {
    for (var key in result)
    {
        if (key==="error")
        {
            console.log("Ale.Api.ErrorHandle: ERROR: Key -> " + result.error + " Description ->" + result.description);

            ErrorNumber=result.error;
            ErrorDescription=result.description;
        }
    };

    if(ErrorNumber!=="")
    {
        Ale.Ui.Loader.Hide();
        Ale.Label.Get("Ale.Api.ErrorHandle: Error-"+ErrorNumber);
        //myApp.dialog.alert(Ale.Label.Get("Error-"+ErrorNumber),config.MyApp["name"]);
        throw new Error("Error: "+ErrorNumber+"::"+ErrorDescription );
    }
};


/**
 * Controlla se c'è un errore nella risposta API
 * ma non lo gestisce
*/
Ale.Api.ErrorCheck = function (result)
{
    if(result.error!==undefined) {

        console.log("Ale.Api.ErrorCheck: Error -> ");
        console.log(result.error);
        console.log("Ale.Api.ErrorCheck: Description -> ");
        console.log(result.description);

    return true;
    }
    else
    {
        return false;
    }
};

Ale.String = function() {};

Ale.String.ReplaceAll = function(str, to_replace, replace_with, ignore)
{
    //console.log("AleReplaceAll: "+str +" -> "+ to_replace + " -> " + replace_with);

    return str.replace(new RegExp(to_replace.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g,"\\$&"),(ignore?"gi":"g")),(typeof(replace_with)=="string")?replace_with.replace(/\$/g,"$$$$"):replace_with);
};


//******************************************************************************
//
// DATETIME FUNCTIONS
//
//******************************************************************************

Ale.DateTime = function(){};

Ale.DateTime.GetTodayAsYYYYMMGG = function(separator)
{
    var today = new Date().toJSON().slice(0,10).replace(/-/g,separator);
    console.log("Ale.DateTime.GetTodayAsYYYYMMGG: Date: "+today);
    return today;
}

Ale.DateTime.GetNextDayAsYYYYMMGG = function(date,separator)
{
    var day = new Date(date);
    var nextDay = new Date(day);

    nextDay.setDate(day.getDate()+1);
    var n= nextDay.toJSON().slice(0,10).replace(/-/g,separator);

    console.log("Ale.DateTime.GetNextDayAsYYYYMMGG: Date: "+date + " Next: "+n);

    return n;
}

Ale.DateTime.GetPrevDayAsYYYYMMGG = function(date,separator)
{
    var day = new Date(date);
    var nextDay = new Date(day);

    nextDay.setDate(day.getDate()-1);
    var n= nextDay.toJSON().slice(0,10).replace(/-/g,separator);

    console.log("Ale.DateTime.GetNextDayAsYYYYMMGG: Date: "+date + " Next: "+n);

    return n;
}

Ale.DateTime.GetTime = function(separator)
{
    var d = new Date();
    var h = d.getHours();
    var m = d.getMinutes();
    
    if(h<10)
    {
        h="0"+h;
    }
    
    if(m<10)
    {
        m="0"+m;
    }
    
    var t = h + separator + m;
    
    console.log("Ale.DateTime.GetTime: " + t);

    return t;    
};


Ale.DateTime.ConvertDate = function(date,format)
{


 //  var options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };

    var s = date;
    var t, d = null;

    t = s.split(/[- :]/);
    d = new Date(t[0], t[1] - 1, t[2], t[3] || 0, t[4] || 0, t[5] || 0);

    if (window.Intl && typeof window.Intl === 'object')
    {
        if(format =="DDMMYYYY")
        var options = { day: 'numeric',month: 'numeric', year: 'numeric'};
        d = d.toLocaleDateString(navigator.language,options);
    }
    else
    {
        d = d.toLocaleDateString();
    }

    console.log("Ale.DateTime.ConvertDate: " + this + " -> " + d);

    return d;


}

/*
 * da :
 * 
 * 2018-10-22
 * 
 * a:
 * 22-10
 * 
 * Nella lingua del browser
 * 
 */

Ale.DateTime.GetDateAsDDMM = function(date)
{
    var s = date;
    var t, d = null;

    t = s.split(/[- :]/);
    d = new Date(t[0], t[1] - 1, t[2], t[3] || 0, t[4] || 0, t[5] || 0);

    if (window.Intl && typeof window.Intl === 'object')
    {
        var options = { month: 'numeric', day: 'numeric'};
        d = d.toLocaleDateString(navigator.language,options);
    }
    else
    {
        d = d.toLocaleDateString();
    }

    console.log("AleToDate: " + this + " -> " + d);

    return d;
};

//******************************************************************************
//
// LABELS FUNCTIONS
//
//******************************************************************************
Ale.Label = function() {};

/**
 * Recupera il valore di una label :
 *
 * name Label name
 *

Esempio di una label:

"App/Property/Share/Message"

Esempio di chiamata:
Ale.Label.Get(App/Property/Share/Message");

 *
 */
Ale.Label.Get = function (name) {

    //console.log("Label Name : "+name);
    //console.log("Label Value: "+label[name]);

    if(label[name]!==undefined) {

        return label[name];

    } else if(label[name.charAt(0).toUpperCase() + name.slice(1)]!==undefined) {

        return label[name.charAt(0).toUpperCase() + name.slice(1)];

    } else {

        console.log("Ale.Label.Get: Error -> Label doesn't exist: "+name);
        return name;
    }
};

Ale.Label.Domain = function() {};


/**
 * Recupera il valore di una label da un dominio:
 *
 * domain Domain name
 * id valore dell'opzione
 *
Esempio di donini di label:

 "is_active":
 [
     {"value":"1","title":"Attivi"},
     {"value":"0","title":"Non Attivi"}
 ],

Example:
Ale.Label.Domain.getTitleByValue("is_active",1);
 *
 */
Ale.Label.Domain.getTitleByValue = function (domain,id)
{
    var labels = label[domain];

    if(labels!==undefined) 
    {

        for (var i = 0; i<labels.length;i++)
        {
            if (labels[i].value == id)
            {
                console.log("Ale.Label.GetFromDomain domain : "+ domain +" For id: " + id +" value: " + labels[i].title);
                return labels[i].title;
            }
        }

        console.log("Ale.Label.GetFromDomain domain : "+ domain +" For id: " + id +" value: undefined");
        return domain+"/"+id;
    }
    else
    {
        console.log("Ale.Label.GetFromDomain domain does not exist: "+ domain);
    }
};

Ale.Label.Domain.getValueByTitle = function (domain,title)
{

    let labels = label[domain];

    if(labels!==undefined) 
    {
        for (let i = 0; i<labels.length;i++)
        {
            if (labels[i].title.toLowerCase() == title.toLowerCase())
            {
                console.log("Ale.Label.Domain.getValueByTitle domain : "+ domain +" For title: " + title +" title: " + labels[i].value);
                return labels[i].value;
            }
        }

        console.log("Ale.Label.Domain.getValueByTitle domain : "+ domain +" For title: " + title +" value: undefined");
        return domain+"/"+title;
    }
    else
    {
        console.log("Ale.Label.Domain.getValueByTitle domain does not exist: "+ domain);
    }
};


Ale.Label.Parse = function (selector)
{
    var d="*";

    if (!Ale.isNull(selector))
    {
        d=selector;
    }

    let myFields = document.querySelectorAll("[data-ale-label]");

    for(var i=0;i<myFields.length;i++)
    {
        
        let tn = myFields[i].tagName;
        let lb = myFields.item(i).getAttribute("data-ale-label");

        if(tn == "DIV")
        {
            //div,option,a,th,td,li
            myFields.item(i).innerHTML=Ale.Label.Get(lb);
        }
    }
};
export default Ale