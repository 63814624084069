import React from 'react';
import { Stack, Text, TextField, PrimaryButton, Icon } from 'office-ui-fabric-react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';

import { Redirect } from 'react-router'


import QrCode from './qr-code'
import ChangePwd from './ale-change-pwd'

import Ale      from '../ale-sdk/ale-mobile'
import AleError from './ale-error'
import config   from '../ale-sdk/ale-config'

export default class AleLogin extends React.Component
{
    constructor(props)
    {
        super(props);

        console.log("Component AleLogin")

        this.state =
        {
            username     : "",
            password     : "",
            otp          : "",
            changepwd    : false,
            loading      : false,

            qrcode       : false,
            otp_valid    : true,

            error        : false,
            error_code   : 0,

            passwordType : "password",

            login        : false,

            reCaptchaToken : ""
        }

        this.componentDidMount      = this.componentDidMount.bind(this);
        this.onChange               = this.onChange.bind(this);
        this.onSubmit               = this.onSubmit.bind(this);
        this.togglePasswordView     = this.togglePasswordView.bind(this);
    }

    componentDidMount ()
    {
        console.log("componentDidMount")
    }

    togglePasswordView()
    {
        if(this.state.passwordType==='password')
        {
            this.setState({ passwordType: "text" });
        }
        else
        {
            this.setState({ passwordType: "password" });
        }
    }

    onChange(e)
    {
        console.log("onChange")

        let value  = e.target.value;
        let name   = e.target.name;

        console.log(value)
        console.log(name)

        if(name === "otp")
        {
            if(value.length === 6)
            {
                this.setState({ otp_valid: true});
            }
            else
            {
                this.setState({ otp_valid: false});
            }
        }

        this.setState({ [name]: value });
    }

    async onSubmit(e)
    {
        console.log("onSubmit")

        e.preventDefault();
        e.stopPropagation();

        this.setState({ error: false,  error_code:0})

        this.setState({ loading: true, qrcode: false, changepwd: false});

        let self = this;
        window.grecaptcha.ready(async function ()
        {
            let token = await window.grecaptcha.execute(config.MyApp.CaptchaSiteKey, {action: 'admin_webapp_login'})

            var data = {
                username               : self.state.username,
                password               : self.state.password,
                otp                    : self.state.otp,
                "g-recaptcha-response" : token
            };

            var c1 = Ale.Api.Call("Gate/User/Login/",data);
            c1.then(function(r) 
            {
                if (Ale.Api.ErrorCheck(r))
                {
                    self.setState({ loading: false })
                    self.setState({ error: true,  error_code:r.error})
                }

                r=r.result;

                if(r.hasOwnProperty('ChangePwd') && r.ChangePwd === "1")
                {
                    self.setState({ loading: false, changepwd: true, username: "", password: "", otp: "" });
                    new (window)["ChangePwd"]("ale-change-pwd");
                }
                else if(r==="true")
                {
                    self.setState({ loading: false });
                    self.setState({ error: true,  error_code:45})
                }
                else if(r.hasOwnProperty('token'))
                {
                    sessionStorage.setItem("token",r.token);
                    self.setState({ loading: false });

                    //self.goToDashboard();
                    self.setState({ login: true});
                    //myApp.loginScreen.close(".login-screen", true);
                }
                else if(r.hasOwnProperty('secKey'))
                {
                    // FIRST LOGIN - GENERATE QR CODE
                    self.setState({ loading: false })
                    self.setState({ qrcode: true, otp_valid: false });

                    // GENERATE QR CODE USING //http://davidshimjs.github.io/qrcodejs/   - MIT LICENSE

                    //var qrcodeel = new (window as any)["QRCode"]("qr-code");
                    var qrcodeel = new (window)["QRCode"]("qr-code");

                    qrcodeel.makeCode("otpauth://totp/IBuyer("+self.state.username+")?secret="+r.secKey);
                }
            }).catch(function(e)
            {
                //Ale.Ui.Loader.Hide();
            });
        })
    }

    render() 
    {

        const containerProps =
        {
            horizontalAlign:'center',
            verticalAlign:'center',
            verticalFill:true,
            styles:
            { 
                root: 
                {
                    //"background-image"        : "url('img/bg.jpg')",
                    "-webkit-background-size" : "cover",
                       "-moz-background-size" : "cover",
                         "-o-background-size" : "cover",
                            "background-size" : "cover",
                                    "opacity" : 0.9
                }
            },

        };

        const formProps = 
        {
            
            horizontalAlign:'center',
            verticalAlign:'center',

            tokens:
            { 
                childrenGap: 15
            },

            styles:
            { 
                root:
                { 
                    width   : "450px", 
                    height  : "400px",
                    opacity : 0.95
                } 
            },
            
        };

        const textProps=
        {
            tokens: { childrenGap: 30},
            styles: { root: { "padding-bottom":"5%"} },
        };

        const fieldgroupProps =
        {
            tokens: { childrenGap: 30},
            styles: { root: { width:"75%"} },

        };


        return (

            <Stack {...containerProps} >
                <Stack {...formProps} className="ale-form-login">
                    <Stack {...textProps}>
                        <Text variant={'xxLarge'} block>Sign In!!</Text>
                    </Stack>
                    
                    <Stack {...fieldgroupProps} >

                        <TextField 
                            name='username' 
                            type='email'
                            value={this.state.username}
                            underlined 
                            placeholder={Ale.Label.Get("L_EMAIL")} 
                            onChange={this.onChange}
                        />
                        
                        <div style={{ display: "flex", alignItems: "center"}}>
                            <TextField 
                                name='password' 
                                //type='password' 
                                value={this.state.password}
                                type={this.state.passwordType}
                                underlined 
                                placeholder={Ale.Label.Get("L_PASSWORD")} 
                                onChange={this.onChange}  
                                style={{width:"336px"}}
                            />
                            <Icon iconName="Eye" style={{ left: "-25px", position: "relative" }} onClick={this.togglePasswordView}  />
                        </div>

                        <TextField 
                            name='otp'
                            type='text'     underlined placeholder="OTP"      onChange={this.onChange} />


                        { this.state.loading === false &&
                                    
                            <PrimaryButton text="Sign In!" 
                                onClick={this.onSubmit} 
                                disabled = {!this.state.otp_valid} 
                            />
                        }

                        { this.state.loading === true &&
                            <Spinner size={SpinnerSize.large}  />
                        }

                        { this.state.qrcode === true &&
                            <QrCode />
                        }

                        { this.state.changepwd === true &&
                            <ChangePwd />
                        }

                        { this.state.error === true &&
                            <AleError error_code={this.state.error_code}/>
                        }
                        
                        { this.state.login === true &&
                            <Redirect to="/dashboard" push />
                        }

                    </Stack>
                </Stack>
            </Stack>
        );
    };
}