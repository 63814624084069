import React from 'react';
import Ale   from '../ale-sdk/ale-mobile'
import AleError from './ale-error'

import { Text, PrimaryButton,TextField, Icon, Modal, MessageBar, MessageBarType, Stack} from 'office-ui-fabric-react';

const tokens = {
    parent : { 
        childrenGap: 10, 
        padding: 10 
    },
    child : {
        childrenGap : 20
    },
    button : {
        padding : 30
    }
}

const style = {
    stackitem : {
        height: 40,
        display: 'flex',
        alignItems: 'left',
        justifyContent: 'center',
    }
}

export default class ChangePwd extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state =
        {
            showModal      : true,
            emptyFld       : false,
            isDraggable    : false,
            newPwdNotMatch : false,
            error          : false,
            otp_valid      : true,
            error_code     : "",
            passwordType   : "password",
            username       : "",
            password       : "",
            new_password   : "",
            rpt_password   : "",
            otp            : ""
        };

        this.submit             = this.submit.bind(this);
        this.onChange           = this.onChange.bind(this);
        this.togglePasswordView = this.togglePasswordView.bind(this);
    }


    submit()
    {
        if(this.state.username === "" || this.state.password === "" || this.state.new_password === "" || this.state.rpt_password === "")
        {
            this.setState({emptyFld: true});
            return;
        }

        if(this.state.new_password !== this.state.rpt_password)
        {
            this.setState({newPwdNotMatch: true, emptyFld: false});
            return;
        }

        this.setState({newPwdNotMatch: false, emptyFld: false, error: false, error_code: ""});

        var data = {
            username : this.state.username,
            password : this.state.password,
            new_pwd  : this.state.new_password,
            otp      : this.state.otp
        };

        let self = this;

        var c1 = Ale.Api.Call("Gate/User/ChngPwd/",data);
        c1.then(function(r) 
        {
            if (Ale.Api.ErrorCheck(r))
            {
                self.setState({ loading: false })
                self.setState({ error: true,  error_code:r.error})
            }
            else
            {
                self.setState({ showModal: false });
            }

        }).catch(function(e)
        {
            //Ale.Ui.Loader.Hide();
        });
    };

    togglePasswordView()
    {
        if(this.state.passwordType==='password')
        {
            this.setState({ passwordType: "text" });
        }
        else
        {
            this.setState({ passwordType: "password" });
        }
    }

    onChange(e)
    {
        let value  = e.target.value;
        let name   = e.target.name;

        if(name === "otp")
        {
            value = value.replace(/[^0-9]/g, '');

            if(value.length === 6)
            {
                this.setState({ otp_valid: true});
            }
            else
            {
                this.setState({ otp_valid: false});
            }
        }

        this.setState({ [name]: value });
    }

    render() 
    {
        const styles =
        {
            list:
            {
                listStyleType : 'none',
            },

            modal:
            {
                padding: '5px',
                height:'600px',
                width: '400px',
                border: '2px'
            },

            center:
            {
                textAlign: 'center'
            }
        }

        return (
            <Modal
            
                isOpen={this.state.showModal}
                onDismiss={this.closeModal}
                isBlocking={false}
                dragOptions={this.state.isDraggable}
                
            >

                <Stack tokens={tokens.parent}>

                    <Stack horizontal tokens={tokens.child}>
                        <Stack  grow={1} className={style.stackitem}>
                            <Text variant={'medium'} block>{Ale.Label.Get("L_PWD_CHNG_TITLE")}</Text>
                        </Stack>
                    </Stack>
                    <Stack horizontal tokens={tokens.child}>
                        <Stack className={style.stackitem}>
                            <TextField 
                                name='username' 
                                type='email' 
                                underlined 
                                placeholder={Ale.Label.Get("L_EMAIL")} 
                                onChange={this.onChange}
                                style={{width:"315px"}}
                                required={true}
                            />
                        </Stack>
                    </Stack>
                    <Stack horizontal tokens={tokens.child}>
                        <Stack className={style.stackitem}>
                            <TextField 
                                name='password' 
                                type={this.state.passwordType}
                                underlined 
                                placeholder={Ale.Label.Get("L_PASSWORD")} 
                                onChange={this.onChange}  
                                style={{width:"315px"}}
                                required={true}
                            />
                            </Stack>
                            <Stack  className={style.stackitem}>
                            <Icon iconName="Eye" style={{ left: "-25px", position: "relative" }} onClick={this.togglePasswordView}  />
                        </Stack>
                    </Stack>
                    <Stack horizontal tokens={tokens.child}>
                        <Stack className={style.stackitem}>
                            <TextField 
                                name='new_password' 
                                type={'password'}
                                underlined 
                                placeholder={Ale.Label.Get("L_NEW_PWD")} 
                                onChange={this.onChange}  
                                style={{width:"315px"}}
                                required={true}
                            />
                        </Stack>
                    </Stack>
                    <Stack horizontal tokens={tokens.child}>
                        <Stack className={style.stackitem}>
                            <TextField 
                                name='rpt_password' 
                                type={'password'}
                                underlined 
                                placeholder={Ale.Label.Get("L_RPT_PWD")} 
                                onChange={this.onChange}  
                                style={{width:"315px"}}
                                required={true}
                            />
                        </Stack>
                    </Stack>
                    <Stack horizontal tokens={tokens.child}>
                        <Stack className={style.stackitem}>
                            <TextField 
                                name='otp' 
                                type={'text'}
                                value={this.state.otp}
                                underlined 
                                placeholder={Ale.Label.Get("L_OTP")} 
                                onChange={this.onChange}  
                                style={{width:"315px"}}
                                required={false}
                            />
                        </Stack>
                    </Stack>
                    <Stack horizontal tokens={tokens.button}>
                        <Stack className={style.stackitem} style={{margin:"auto"}}>
                            <PrimaryButton 
                                text= {Ale.Label.Get("L_OK")}
                                onClick={this.submit}
                                disabled = {!this.state.otp_valid}
                                style={{width:"120px"}}
                            />
                        </Stack>
                    </Stack>
                    <Stack horizontal tokens={tokens.child}>
                        <Stack  grow={1} className={style.stackitem}>
                            { this.state.newPwdNotMatch &&
                                <MessageBar messageBarType={MessageBarType.error} isMultiline={false}> 
                                    {Ale.Label.Get("L_PWD_NOT_MATCH")}
                                </MessageBar>
                            }
                            { this.state.emptyFld &&
                                <MessageBar messageBarType={MessageBarType.error} isMultiline={false}> 
                                    {Ale.Label.Get("L_EMPTY_FIELD")}
                                </MessageBar>
                            }
                            { this.state.error === true &&
                                <AleError error_code={this.state.error_code}/>
                            }
                        </Stack>
                    </Stack>
                </Stack>
            </Modal>
        )
    }
}