//import Ale   from '../ale-sdk/ale-mobile'

import React    from 'react';
import ReactDOM from 'react-dom';

import { Nav } from 'office-ui-fabric-react/lib/Nav';

import Ale      from '../ale-sdk/ale-mobile'
import AleError from './ale-error'

import AleDataModelTree     from './ale-datamodel-tree';
import AleJsonGeneratorTree from './ale-jsongenerator-tree';
import AleDataModelMaskTree from './ale-datamodelmask-tree';
import AleApiList           from './ale-api-list';
import AleApiForm           from './ale-api-form';
import AleRoleList          from './ale-role-list';
import AleUserList          from './ale-user-list';
import AleRBAC              from './ale-rbac';


/**
 * TO DO MENU FOR JSON MASK
 */
var menu = 
[
    {
        links: 
        [
            { key: "L_M_DASHBOARD",     name: '', icon: 'Stats', click:false },

            { name:'', key: 'L_M_DATA_MODEL',
                links: [
                    { key: "L_M_TREE",     name: '', icon: 'Tree',        component: AleDataModelTree,     click:true },
                    { key: "L_M_MASK",     name: '', icon: 'Mask',        component: AleDataModelMaskTree, click:true },
                    { key: "L_M_TYPES",    name: '', icon: 'Contrast',    component:"" },
                    { key: "L_M_JSON_GEN", name: '', icon: 'CodeWorking', component: AleJsonGeneratorTree, click:true }
                ],
                isExpanded: true
            },

            {  name:'', key: 'L_M_USERS_AND_ROLES', isExpanded: true,
                links: [
                    { key: "L_M_USERS",    name: '', icon: 'People', component:AleUserList, click:true },
                    { key: "L_M_ROLES",    name: '', icon: 'Person', component:AleRoleList, click:true },
                ]
            },

            {  name:'', key: 'L_M_SERVICES_AND_APIS', isExpanded: true,
                links: [
                    { key: "L_M_APIS",    name: '', icon: 'Hammer', component:AleApiList, click:true },
                    { key: "L_M_API_ADD", name: '', icon: 'Add',    component:AleApiForm, click:true },
                ]

            },

            {  name:'', key: 'L_M_RBAC'         , icon: 'ThumbsUp', component:AleRBAC, click:true },
            {  name:'', key: 'L_M_DOCUMENTATION', icon: 'HelpCircleOutline'}
                
        ]
    }
]


export default class AleMenu extends React.Component
{
    constructor(props)
    {
        super(props)

        console.log("Component AleMenu")

        this.state =
        {
            selectedKey: "L_M_DASHBOARD",
            groups :""
           
        }

        this.componentDidMount   = this.componentDidMount.bind(this);
        this.labelize            = this.labelize.bind(this);
        this.onLinkClick         = this.onLinkClick.bind(this);

    }

    componentDidMount ()
    {
        console.log("componentDidMount")
        this.labelize();
    }

    labelize()
    {
        console.log(menu)

        var my_json = menu

        function iterate(my_json)
        {

            my_json.forEach(function(item)
            {

                if(!Ale.isNull(item["key"]))
                {
                    console.log(item["key"])
                    item["name"] = Ale.Label.Get(item["key"])
                }

                Object.keys(item).forEach(key =>
                {
                    //console.log(typeof item[key]);

                    if (typeof item[key] === 'object')
                    {
                        //console.log(item["key"])
                        iterate(item[key])
                    }
                })
            })
        }

        iterate(my_json)
        this.setState({groups:my_json})
    }

    onLinkClick(ev, item)
    {
        if(item.click===true)
        {
            this.setState({selectedKey:item.key})
    
            if (item)
            {   
                const my_c = React.createElement(item.component, {});
                ReactDOM.render(
                    my_c,
                    document.getElementById('ale-content')
                );
            }
        }
    }

    render() 
    {
        return (
            <Nav
                onLinkClick={this.onLinkClick}
                expandedStateText="expanded"
                collapsedStateText="collapsed"
                selectedKey={this.state.selectedKey}
                expandButtonAriaLabel="Expand or collapse"
                groups={this.state.groups}
            />
        )
    }
}

