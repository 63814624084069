import React from 'react';
import { registerIcons } from '@uifabric/styling';
import { Icon } from '@iconify/react';


import add               from '@iconify/icons-ion/md-add';
import cancel            from '@iconify/icons-ion/md-close';
import ChevronDown       from '@iconify/icons-ion/md-arrow-dropdown';
import ChevronRightMed   from '@iconify/icons-ion/md-arrow-dropright';
import Tag               from '@iconify/icons-ion/md-pricetag';
import PersonAdd         from '@iconify/icons-ion/md-person-add';
import Logout            from '@iconify/icons-ion/md-log-out';
import Eye               from '@iconify/icons-ion/md-eye';
import Tree              from '@iconify/icons-ion/md-git-branch';
import Contrast          from '@iconify/icons-ion/md-contrast';
import Cog               from '@iconify/icons-ion/md-cog';
import Settings          from '@iconify/icons-ion/md-settings';
import Hammer            from '@iconify/icons-ion/md-hammer';
import People            from '@iconify/icons-ion/md-people';
import Person            from '@iconify/icons-ion/md-person';
import Switch            from '@iconify/icons-ion/md-switch';
import HelpCircleOutline from '@iconify/icons-ion/md-help-circle-outline';
import CodeWorking       from '@iconify/icons-ion/md-code-working';
import ThumbsUp          from '@iconify/icons-ion/md-thumbs-up';
import Search            from '@iconify/icons-ion/md-search';
import Sortdown          from '@iconify/icons-ion/md-arrow-dropdown';
import Sortup            from '@iconify/icons-ion/md-arrow-dropup';
import MoreVertical      from '@iconify/icons-ion/md-more';
import Checkmark         from '@iconify/icons-ion/md-checkmark';
import Stats             from '@iconify/icons-ion/md-stats';
import Mask              from '@iconify/icons-ion/md-eye-off';





registerIcons({
    icons: {
        'add'               :  <Icon icon={add} />,
        'cancel'            :  <Icon icon={cancel} />,
        'ChevronDown'       :  <Icon icon={ChevronDown} />,
        'ChevronRightMed'   :  <Icon icon={ChevronRightMed} />,
        'Tag'               :  <Icon icon={Tag} />,
        'PersonAdd'         :  <Icon icon={PersonAdd} />,
        'Logout'            :  <Icon icon={Logout} />,
        'Eye'               :  <Icon icon={Eye} />,
        'Tree'              :  <Icon icon={Tree} />,
        'Contrast'          :  <Icon icon={Contrast} />,
        'Cog'               :  <Icon icon={Cog} />,
        'Settings'          :  <Icon icon={Settings} />,
        'Hammer'            :  <Icon icon={Hammer} />,
        'People'            :  <Icon icon={People} />,
        'Person'            :  <Icon icon={Person} />,
        'Switch'            :  <Icon icon={Switch} />,
        'HelpCircleOutline' :  <Icon icon={HelpCircleOutline} />,
        'CodeWorking'       :  <Icon icon={CodeWorking} />,
        'ThumbsUp'          :  <Icon icon={ThumbsUp} />,
        'Search'            :  <Icon icon={Search} />,
        'Sortdown'          :  <Icon icon={Sortdown} />,
        'Sortup'            :  <Icon icon={Sortup} />,
        'MoreVertical'      :  <Icon icon={MoreVertical} />,
        'Checkmark'         :  <Icon icon={Checkmark} />,
        'Stats'             :  <Icon icon={Stats} />,
        'Mask'              :  <Icon icon={Mask} />,
    }
});

export default class App extends React.Component
{
}