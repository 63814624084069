const config = {

    "MyApp" :
    {
        "Name"            : "AleMicro3 dashboard", 
        "Version"         : "2",
        "Copyright"       : "Copyright © 2019",
        "Authors"         : "Ale",
        "JsLog"           : true,
        "AjaxTimeout"     : 10000,
        "ApiUrl"          : "https://api.properties-test.net/v1/",
        "CaptchaSiteKey"  : "6LfmjPIUAAAAAEys60zdGhOnaIF5_jvuqKUHSm9a",

    }
};

if(!config.MyApp.JsLog)
{
    console.log = function(){};
}

export default config