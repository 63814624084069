//
//'root'
//'class'
//'field'
//'structure'
//'array-of-structure'

import React    from 'react';
import ReactDOM from 'react-dom';

import { TextField, CommandBarButton, Spinner, DetailsList, DetailsListLayoutMode, SpinnerSize,
        SelectionMode, IconButton,Dialog,DialogType,DialogFooter,PrimaryButton } from 'office-ui-fabric-react';

import Ale      from '../ale-sdk/ale-mobile'
import AleError from './ale-error'

import AleUserForm      from './ale-user-form'


export default class AleUserList extends React.Component
{
    constructor(props)
    {
        super(props);

        this.componentDidMount  = this.componentDidMount.bind(this);
        this.getData            = this.getData.bind(this);

        this.onColumnClick      = this.onColumnClick.bind(this);
        this.onChangeText       = this.onChangeText.bind(this);
        this.onItemInvoked      = this.onItemInvoked.bind(this);
        this.onCellContent      = this.onCellContent.bind(this);
        this.onGetExample       = this.onGetExample.bind(this);
        this.onToggleStatus     = this.onToggleStatus.bind(this);
        this.onAddUser          = this.onAddUser.bind(this);

        this.state =
        {
            loading    : false,
            error      : false,
            error_code : 0,
            items      : [],
            items_copy : [],
            curr_item  : {example:"IA=="},
            hideExample: true,
            redirectToAddApi : false,
            columns    : [
                { 
                    key: 'id',
                    name: Ale.Label.Get("L_ID"),
                    fieldName: 'id',
                    minWidth: 64,
                    maxWidth: 64,
                    isResizable: true,
                    onColumnClick: this.onColumnClick,
                    data: 'number',
                
                  onRender: (item) => {
                    return( 
                        <div className="ale-list-cell">
                            <div className="ale-center-vertical float-left">
                                {item.id}
                            </div>

                            <div className="ale-center-vertical float-right">
                            <IconButton
                                menuIconProps={{ iconName: 'MoreVertical' }}
                                menuProps={{
                                    items: [
                                        {
                                            key: 'Enable_Disable',
                                            text: Ale.Label.Get("L_ENABLE_DISABLE"),
                                                onClick: () => this.onToggleStatus(item, "status")
                                        },
                                        {
                                            key: 'Enable_Disable_Login',
                                            text: Ale.Label.Get("L_ENABLE_DISABLE_LOGIN"),
                                                onClick: () => this.onToggleStatus(item, "login")
                                        },
                                        {
                                            key: 'Reset_TFA',
                                            text: Ale.Label.Get("L_RESET_TFA"),
                                                onClick: () => this.resetTFA(item)
                                        },
                                        {
                                            key: 'Modify',
                                            text: Ale.Label.Get("L_MODIFY"),
                                                onClick: () => this.onModify(item)
                                        }
                                    ]
                                }}
                            />
                        </div>

                    </div>
                    )
                  }
                },
                {
                    key: 'name',
                    name: Ale.Label.Get("L_NAME"),
                    fieldName: 'name',
                    minWidth: 90,
                    maxWidth: 90,
                    isResizable: true,
                    isCollapsible: true,
                    data: 'string',
                    onColumnClick: this.onColumnClick,
                    onRender: (item) => {
                      return (
                          this.onCellContent(item.name)
                      )
                    }
                },
                {
                    key: 'surname',
                    name: Ale.Label.Get("L_SURNAME"),
                    fieldName: 'surname',
                    minWidth: 90,
                    maxWidth: 90,
                    isResizable: true,
                    isCollapsible: true,
                    data: 'string',
                    onColumnClick: this.onColumnClick,
                    onRender: (item) => {
                      return (
                          this.onCellContent(item.surname)
                      )
                    }
                },
                {
                    key: 'username',
                    name: Ale.Label.Get("L_USERNAME"),
                    fieldName: 'username',
                    minWidth: 200,
                    maxWidth: 200,
                    isResizable: true,
                    isCollapsible: true,
                    data: 'string',
                    onColumnClick: this.onColumnClick,
                    onRender: (item) => {
                      return (
                          this.onCellContent(item.username)
                      )
                    }
                },
                {
                    key: 'user_role',
                    name: Ale.Label.Get("L_ROLE_NAME"),
                    fieldName: 'user_role',
                    minWidth: 90,
                    maxWidth: 90,
                    isResizable: true,
                    isCollapsible: true,
                    data: 'string',
                    onColumnClick: this.onColumnClick,
                    onRender: (item) => {
                      return (
                          this.onCellContent(item.user_role)
                      )
                    }
                },
                {
                    key: 'user_ou',
                    name: Ale.Label.Get("L_USER_OU"),
                    fieldName: 'user_ou',
                    minWidth: 140,
                    maxWidth: 140,
                    isResizable: true,
                    isCollapsible: true,
                    data: 'string',
                    onColumnClick: this.onColumnClick,
                    onRender: (item) => {
                      return (
                          this.onCellContent(item.user_ou)
                      )
                    }
                },
                {
                    key: 'usedTFA',
                    name: Ale.Label.Get("L_USED_TFA"),
                    fieldName: 'usedTFA',
                    minWidth: 75,
                    maxWidth: 75,
                    isResizable: true,
                    isCollapsible: true,
                    data: 'string',
                    onColumnClick: this.onColumnClick,
                    onRender: (item) => {
                      return (
                          this.onCellContent(item.usedTFA)
                      )
                    }
                  },
                  {
                    key: 'login',
                    name: Ale.Label.Get("L_LOGIN"),
                    fieldName: 'login',
                    minWidth: 75,
                    maxWidth: 75,
                    isResizable: true,
                    isCollapsible: true,
                    data: 'string',
                    onColumnClick: this.onColumnClick,
                    onRender: (item) => {
                      return (
                          this.onCellContent(item.login)
                      )
                    }
                  },
                  {
                  key: 'status',
                  name: Ale.Label.Get("L_STATUS"),
                  fieldName: 'status',
                  minWidth: 64,
                  maxWidth: 64,
                  isResizable: true,
                  isCollapsible: true,
                  data: 'string',
                  onColumnClick: this.onColumnClick,
                  onRender: (item) => {
                    return (
                        this.onCellContent(Ale.Label.Domain.getTitleByValue("is_active",item.status))
                    )
                  }
                },
                {
                    key: 'updated',
                    name: Ale.Label.Get("L_UPDATED"),
                    fieldName: 'updated',
                    minWidth: 60,
                    maxWidth:60,
                    isResizable: true,
                    isCollapsible: true,
                    data: 'date',
                    onColumnClick: this.onColumnClick,
                    onRender: (item) => {
                        return (
                            this.onCellContent(item.updated)
                        )
                      }
                  }
              ]
        };
    }

    /*{
        "tenant": "XA",
        "updated_by": "null",
        "user_ou": "System",
        "user_role": "Admin",
        "user_type": "System",
        "username": "ranokkio@ranokkio.com"
    },*/

    componentDidMount ()
    {
        console.log("componentDidMount")
        this.getData()
    }

    getData()
    {
        this.setState({ loading: true })
        var params = {
            token    : sessionStorage.getItem("token")
        };

        var c1 = Ale.Api.Call("Gate/User/List/",params);
        let self = this;

        c1.then(function(r)
        {
            if (Ale.Api.ErrorCheck(r))
            {
                self.setState({ loading: false })
                self.setState({ error: true,  error_code:r.error})
            }
            else
            {
                self.setState({ loading: false, items: r.result, items_copy: r.result})
                self.getTree()
            }
        }).catch(function(e)
        {
            //Ale.Ui.Loader.Hide();
        });
    }

    onCellContent(value)
    {
        //console.log(value)
        return(
            <div className="ale-list-cell">
                <div className="ale-center-vertical">
                    {value}
                </div>
            </div>
        )
    }

    onColumnClick (ev, column)
    {
        const { columns, items } = this.state;
        const newColumns = columns.slice();
        const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0];

        newColumns.forEach((newCol) => {
            if (newCol === currColumn)
            {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            }
            else
            {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });

        const newItems = _copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);
        this.setState({
            columns: newColumns,
            items: newItems
        });


        function _copyAndSort(items, columnKey, isSortedDescending)
        {
            const key = columnKey;
                return items.slice(0).sort((a, b) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
        }
    };

    onChangeText (ev, text) 
    {
        text     = text.toLowerCase();
        this.setState({
            items: text ? this.state.items_copy.filter(i => i.username.toLowerCase().indexOf(text) > -1) : this.state.items_copy
        });

    };
    
    onItemInvoked(item)
    {
        alert(`Item invoked: ${item.id}`);
    }

    onGetExample(item)
    {
        this.setState({hideExample:false,curr_item:item})
    }

    onToggleStatus(item, updateField)
    {
        var data = {
            token   : sessionStorage.getItem("token"),
            id      : item.id
        };

        if(updateField==="status")
        {
            data.status = item.status === "1" ? "0" : "1";
        }
        else if(updateField==="login")
        {
            data.login = item.login === "1" ? "0" : "1";
        }
        else
        {
            return
        }

        var c1 = Ale.Api.Call("Gate/User/Update/",data);

        let self=this;

        c1.then(function(r) 
        {
            if (Ale.Api.ErrorCheck(r))
            {
                self.setState({ error_text:r.error + ": " + r.description, successApiCall: false});
            }
            else
            {
                self.setState({ error_text: "", successApiCall: true });
                self.getData();
            }

        }).catch(function(e)
        {

        });
    }

    resetTFA(item)
    {
        var data = {
            token   : sessionStorage.getItem("token"),
            user_id : item.id
        };

        var c1 = Ale.Api.Call("Gate/User/ResetTFA/",data);

        let self=this;

        c1.then(function(r) 
        {
            if (Ale.Api.ErrorCheck(r))
            {
                self.setState({ error_text:r.error + ": " + r.description, successApiCall: false});
            }
            else
            {
                self.setState({ error_text: "", successApiCall: true });
                self.getData();
            }

        }).catch(function(e)
        {

        });
    }

    onModify(item)
    {
        console.log(item);
        ReactDOM.render(
            <AleUserForm item={item}/>,
            document.getElementById('ale-content')
        )
    }

    onAddUser()
    {
        console.log("onAddUser");

        ReactDOM.render(
            <AleUserForm />,
            document.getElementById('ale-content')
        )
    }

    render()
    {
        return (
        <>

            <div id="ale-sub-navbar" className="flex-row sticky-top d-flex ale-sub-navbar">
                <CommandBarButton
                    data-automation-id="cbb-1"
                    iconProps={{ iconName: 'Add' }}
                    text={Ale.Label.Get("L_ADD_USER")}
                    onClick={this.onAddUser}
                />

            </div>

            <div className="flex-row p-3">
                <div style={{width: "300px"}}>
                    <TextField 
                        placeholder={Ale.Label.Get("L_FILTER_BY_USERNAME")} 
                        underlined 
                        iconProps={{ iconName: 'Search' }} 
                        onChange={this.onChangeText}
                    />
                </div>
            </div>
            
            <div id="ale-section" className="flex-row w-100 p-3">
                { this.state.loading === false &&

                    <DetailsList
                        items={this.state.items}
                        compact={true}
                        columns={this.state.columns}
                        selectionMode={SelectionMode.none}
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        onItemInvoked={this.onItemInvoked}
                        className={"ale-list"}
                    />
                }

                { this.state.loading === true &&
                    <Spinner size={SpinnerSize.large}  />
                }

                { this.state.error === true &&
                    <AleError error_code={this.state.error_code}/>
                }
                
                <Dialog
                   
                    hidden={this.state.hideExample}
                    onDismiss={() => this.setState({hideExample:true})}
                    maxWidth="50%"
                    dialogContentProps={{
                        type: DialogType.normal,
                        title: `{{url}}/${this.state.curr_item.service}/${this.state.curr_item.obj}/${this.state.curr_item.evt}/`,
                        subText: JSON.stringify((atob(this.state.curr_item.example)), null, 4),
                      
                    }}
                    modalProps={{
                        isBlocking: false,
                        dragOptions: true,
                    }}
                >
                    <DialogFooter>
                        <PrimaryButton onClick={() => this.setState({hideExample:true})} text={Ale.Label.Get("L_CANCEL")} />
                    </DialogFooter>
                </Dialog>
            </div>

        </>
    );
  }
}