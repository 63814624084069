import React from 'react';
import Ale   from '../ale-sdk/ale-mobile'

import { Text, PrimaryButton,Link, Modal} from 'office-ui-fabric-react';



export default class QrCode extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state =
        {
            showModal : true,
            isDraggable:true
        }

        this.closeModal = this.closeModal.bind(this)
    }


    closeModal()
    {
        this.setState({ showModal: false });
    };


    render() 
    {

        const styles =
        {
            list:
            {
                listStyleType : 'none'
            },

            modal:
            {
                padding: '10px',
                height:'600px',
                width: '400px',
                border: '2px',
            },

            title:
            {
                textAlign: 'center'
            }
        }

        return (
            <Modal
            
                isOpen={this.state.showModal}
                onDismiss={this.closeModal}
                isBlocking={false}
                dragOptions={this.state.isDraggable}
            >

                <div style={styles.modal}>

                    <h3 style={styles.title}>{Ale.Label.Get("L_QR_INSTR_HEAD")}</h3>

                    <h5 style={styles.title}>{Ale.Label.Get("L_APP_TITLE") + Ale.Label.Get("L_QR_INSTR_BODY1")}</h5>

                    <ul style={styles.list}>
                        
                        <li>
                            <Text variant={'medium'} block>{Ale.Label.Get("L_QR_INSTR_BODY2")}</Text>
                        </li>
                        
                        <li>
                            <br />
                        </li>
                        
                        <li>
                            <ul>
                                <li><Link href={"https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"}>Android</Link></li>
                                <li><Link href={"https://itunes.apple.com/gb/app/google-authenticator/id388497605"}>Apple</Link></li>
                                <li><Link href={"https://www.microsoft.com/en-gb/store/p/microsoft-authenticator/9nblgggzmcj6"} >Windows</Link></li>
                            </ul>
                        </li>

                        <li>
                            <br />
                        </li>
                        
                        <li>
                            <Text variant={'medium'} block>{Ale.Label.Get("L_QR_INSTR_BODY3")}</Text>
                        </li>

                        <li>
                            <br />
                        </li>

                        <li>

                            <p id="qr-code" style={{border:"6px solid #FFFFFF",width:"268px"}}></p>

                        </li>

                        <li>
                            <br />
                        </li>

                        <li>
                            <PrimaryButton text= {Ale.Label.Get("L_OK")}  onClick={this.closeModal} />
                        </li>

                    </ul>

                </div>

            </Modal>
        )
    }
}