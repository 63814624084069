import React from 'react';
import Ale   from '../ale-sdk/ale-mobile'

import {Stack,TextField,PrimaryButton,Text,Dropdown,MessageBar,MessageBarType} from 'office-ui-fabric-react';

/*
service,
obj
evt
description
status
example
*/
export default class AleUserForm extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state =
        {
            name           : "",
            surname        : "",
            username       : "",
            password       : "",
            userrole_id    : "",
            userou_id      : "",
            status         : "",
            login          : "",
            successApiCall : false,
            userRoleLoading: true,
            userRoleList   : [],
            error_text     : "",
            action         : "create"
        }

        this.textOnChange   = this.textOnChange.bind(this);
        this.selectOnChange = this.selectOnChange.bind(this);
        this.onSubmit       = this.onSubmit.bind(this);
    }

    componentDidMount()
    {
        console.log(this.props);
        this.getUserRoleList();

        if(this.props.hasOwnProperty('item'))
        {
            this.setState({ username:    this.props.item.username, 
                            name:        this.props.item.name, 
                            surname:     this.props.item.surname,
                            userrole_id: this.props.item.user_role_id,
                            userou_id:   this.props.item.user_ou_id,
                            status:      this.props.item.status,
                            login:       this.props.item.login,
                            password:    "*****",
                            action:      "update"});
        }
    }

    textOnChange(e)
    {
        let name = e.target.name;
        let value = e.target.value;

        if(name === "name" || name === "surname")
        {
            value = value.replace(/[^a-zA-Z]/g, '');
        }
        else if(name === "username")
        {
            value = value.replace(/[^0-9a-zA-Z@.]/g, '');
        }

        this.setState({[name]: value});
    }

    selectOnChange(e, item)
    {
        let id = e.target.id;
        this.setState({[id]: item.key});
    }

    onSubmit()
    {
        var data = {
            token         : sessionStorage.getItem("token"),
            name          : this.state.name,
            surname       : this.state.surname,
            username      : this.state.username,
            password      : this.state.password === "*****" ? "" : this.state.password,
            XaUserRole_ID : this.state.userrole_id,
            XaUserOu_ID   : this.state.userou_id,
            login         : this.state.login,
            status        : this.state.status
        };

        var api = "Gate/User/Create/";
        if(this.state.action === 'update')
        {
            api = "Gate/User/Update/"
            data.id = this.props.item.id;
        }

        var c1 = Ale.Api.Call(api,data);

        let self=this;

        c1.then(function(r) 
        {
            if (Ale.Api.ErrorCheck(r))
            {
                self.setState({ error_text:r.error + ": " + r.description, successApiCall: false});
            }
            else
            {
                self.setState({ error_text: "", successApiCall: true });
            }

        }).catch(function(e)
        {

        });
    }

    getUserRoleList()
    {
        var data = {
            token    : sessionStorage.getItem("token")
        };
        var c1 = Ale.Api.Call("Gate/Rbac/RoleList/",data);

        let self = this;
        
        c1.then(function(r) 
        {
            Ale.Api.ErrorHandle(r);

            self.setState({
                userRoleLoading : false,
                userRoleList    : r.result
            });

        }).catch(function(e)
        {
            //Ale.Ui.Loader.Hide();
        });
    }

    generateUserRoleList()
    {
        let userRoleList = [];

        if(!this.state.userRoleLoading)
        {
            for(var i = 0; i < this.state.userRoleList.length; i++)
            {
                userRoleList.push({ key: this.state.userRoleList[i].id, text: this.state.userRoleList[i].name})
            }
        }

        return userRoleList;
    }

    render() 
    {
        const fieldgroupProps =
        {
            tokens: { childrenGap: "5%"},
            styles: { root: { width:"75%"} },

        };

        let user_role_list    = this.generateUserRoleList();

        return (

            <>
            <div id="ale-sub-navbar" className="flex-row sticky-top d-flex ale-sub-navbar justify-content-center">
                <Text block>Add a new api</Text>
            </div>

            <div id="ale-section" className="flex-row w-100 p-3 ">
               
                <div className="flex-row w-100 ale-background-darker" style={{overflowY:"scroll"}}>

                    <div className="col-md-5 float-left h-100 p-3 ale-background-darker">

            
                        <Stack {...fieldgroupProps} className={"ale-form-default"}>
                            <TextField name='username' underlined label={Ale.Label.Get("L_USERNAME")} type="text"  required onChange={this.textOnChange} value={this.state.username} disabled={this.state.action === 'update' ? true : false}/>
                            <TextField name='name'     underlined label={Ale.Label.Get("L_NAME")}     type="text"  required onChange={this.textOnChange} value={this.state.name}/>
                            <TextField name='surname'  underlined label={Ale.Label.Get("L_SURNAME")}  type="text"  required onChange={this.textOnChange} value={this.state.surname}/>
                            <Dropdown
                                id = 'status'
                                placeholder={Ale.Label.Get("L_STATUS")}
                                onChange={this.selectOnChange}
                                options={[{ key: "1", text: Ale.Label.Domain.getTitleByValue("is_active","1")},
                                          { key: "0", text: Ale.Label.Domain.getTitleByValue("is_active","0")}  ]}
                                selectedKey={this.state.status}
                                required={true}
                            />
                        </Stack>

                    </div>,

                    <div className="col-md-5 float-left h-100 p-3 ale-background-darker">
                        <Stack {...fieldgroupProps} className={"ale-form-default"}>
                            <TextField name='password'    underlined label={Ale.Label.Get("L_PASSWORD")}       type="text"  required onChange={this.textOnChange} value={this.state.password}/>
                            
                            <Dropdown
                                id = 'userrole_id'
                                placeholder={Ale.Label.Get("L_ROLE_NAME")}
                                onChange={this.selectOnChange}
                                options={user_role_list}
                                selectedKey={this.state.userrole_id}
                                required={true}
                            />

                            <Dropdown
                                id = 'userou_id'
                                placeholder={Ale.Label.Get("L_USER_OU")}
                                onChange={this.selectOnChange}
                                options={[{ key: "1", text: "System"},
                                          { key: "2", text: "Services"}  ]}
                                selectedKey={this.state.userou_id}
                                required={true}
                            />
                            <Dropdown
                                id = 'login'
                                placeholder={Ale.Label.Get("L_LOGIN")}
                                onChange={this.selectOnChange}
                                options={[{ key: "1", text: Ale.Label.Get("L_LOGIN_ENABLED")},
                                          { key: "0", text: Ale.Label.Get("L_LOGIN_DISABLED")} ]}
                                selectedKey={this.state.login}
                                required={true}
                            />
                        </Stack>
                    </div>

                    <div className="row w-100 p-3 justify-content-center">

                            <PrimaryButton text={this.state.action === 'update' ? Ale.Label.Get("L_EDIT_USER") : Ale.Label.Get("L_ADD_USER")}
                                onClick={this.onSubmit} 
                            />

                    </div>

                    <div className="row w-100 p-3 justify-content-center">
                        { this.state.error_text !== "" &&
                            <MessageBar messageBarType={MessageBarType.error} isMultiline={false} dismissButtonAriaLabel="Close">
                                {this.state.error_text}
                            </MessageBar>
                        }

                        { this.state.successApiCall &&
                            <MessageBar messageBarType={MessageBarType.success} isMultiline={false}>
                                {this.state.action === 'update' ? Ale.Label.Get("L_DATA_MODEL_ELEMENT_UPDATED") : Ale.Label.Get("L_DATA_MODEL_ELEMENT_ADDED")}
                            </MessageBar>
                       }
                    </div>

                </div>
        </div>
        </>
        )
    }
}

/*{
	"token":"deca7448a6a6f762283f171a28f7bfc7290ba17c95b3a9717c2befabcce2729d",
	"XaUserRole_ID":"1",
    "XaUserOu_ID":"1"
}*/