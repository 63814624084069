//
//'root'
//'class'
//'field'
//'structure'
//'array-of-structure'

import React    from 'react';
import ReactDOM from 'react-dom';

import { TextField, CommandBarButton, Spinner, DetailsList, DetailsListLayoutMode, SpinnerSize,
        SelectionMode, IconButton,Dialog,DialogType,DialogFooter,PrimaryButton } from 'office-ui-fabric-react';

import Ale      from '../ale-sdk/ale-mobile'
import AleError from './ale-error'

import AleApiForm      from './ale-api-form'


export default class AleApiList extends React.Component
{
    constructor(props)
    {
        super(props);

        this.componentDidMount  = this.componentDidMount.bind(this);
        this.getData            = this.getData.bind(this);

        this.onColumnClick      = this.onColumnClick.bind(this);
        this.onChangeText       = this.onChangeText.bind(this);
        this.onItemInvoked      = this.onItemInvoked.bind(this);
        this.onCellContent      = this.onCellContent.bind(this);
        this.onGetExample       = this.onGetExample.bind(this);
        this.onToggleStatus     = this.onToggleStatus.bind(this);
        this.onModify           = this.onModify.bind(this);
        this.onAddApi           = this.onAddApi.bind(this);

        this.state =
        {
            loading    : false,
            error      : false,
            error_code : 0,
            items      : [],
            items_copy : [],
            curr_item  : {example:"bnVsbA=="},
            hideExample: true,
            redirectToAddApi : false,
            columns    : [
                
                /*
                {
                  key: 'column1',
                  name: 'File Type',
                  className: classNames.fileIconCell,
                  iconClassName: classNames.fileIconHeaderIcon,
                  ariaLabel: 'Column operations for File type, Press to sort on File type',
                  iconName: 'Page',
                  isIconOnly: true,
                  fieldName: 'name',
                    minWidth: 16,
                    maxWidth: 16,
                    onColumnClick: this._onColumnClick,
                    onRender: (item: IDocument) => {
                        return <img src={item.iconName} className={classNames.fileIconImg} alt={item.fileType + ' file icon'} />;
                    }
                },*/
                /*
                description: "null"
evt: "Login"
id: "1"
obj: "User"
service: "Gate"
status: "1"
updated: "2019-01-24 19:30:47"
*/
                { 
                    key: 'id',
                    name: Ale.Label.Get("L_ID"),
                    fieldName: 'id',
                    minWidth: 64,
                    maxWidth: 64,
                    isResizable: true,
                    onColumnClick: this.onColumnClick,
                    data: 'number',
                
                  onRender: (item) => {
                    return( 
                        <div className="ale-list-cell">
                            <div className="ale-center-vertical float-left">
                                {item.id}
                            </div>

                            <div className="ale-center-vertical float-right">
                            <IconButton
                                menuIconProps={{ iconName: 'MoreVertical' }}
                                //className={"ale-list-icon"}
                                //role="button"
                                //aria-haspopup={true}
                                //aria-label="Show actions"
                                //styles={{ root: {border: "1px solid green"} }}
                                menuProps={{
                                    items: [
                                        {
                                        key: 'Example',
                                        text: Ale.Label.Get("L_EXAMPLE"),
                                            onClick: () => this.onGetExample(item)
                                        },
                                        {
                                        key: 'Enable_Disable',
                                        text: Ale.Label.Get("L_ENABLE_DISABLE"),
                                            onClick: () => this.onToggleStatus(item)
                                        },
                                        {
                                        key: 'Modify',
                                        text: Ale.Label.Get("L_MODIFY"),
                                            onClick: () => this.onModify(item)
                                        }
                                    ]
                                }}
                            />
                        </div>

                    </div>
                    )
                  }
                },
                {
                  key: 'service',
                  name: Ale.Label.Get("L_SERVICE"),
                  fieldName: 'service',
                  minWidth: 128,
                  maxWidth: 128,
                  isResizable: true,
                  isCollapsible: true,
                  data: 'string',
                  onColumnClick: this.onColumnClick,
                  onRender: (item) => {
                        return (
                            this.onCellContent(item.service)
                        )
                  },
                  isPadded: true
                },
                {
                  key: 'obj',
                  name: Ale.Label.Get("L_OBJ"),
                  fieldName: 'obj',
                  minWidth: 128,
                  maxWidth: 128,
                  isResizable: true,
                  isCollapsible: true,
                  data: 'string',
                  onColumnClick: this.onColumnClick,
                  onRender: (item) => {
                    return (
                        this.onCellContent(item.obj)
                    )
                  }
                },
                {
                  key: 'evt',
                  name: Ale.Label.Get("L_EVT"),
                  fieldName: 'evt',
                  minWidth: 200,
                  maxWidth: 256,
                  isResizable: true,
                  isCollapsible: true,
                  data: 'string',
                    onColumnClick: this.onColumnClick,
                    onRender: (item) => {
                    return (
                        this.onCellContent(item.evt)
                    )
                  }
                }
                ,
                {
                  key: 'status',
                  name: Ale.Label.Get("L_STATUS"),
                  fieldName: 'status',
                  minWidth: 64,
                  maxWidth: 64,
                  isResizable: true,
                  isCollapsible: true,
                  data: 'string',
                  onColumnClick: this.onColumnClick,
                  onRender: (item) => {
                    return (
                        this.onCellContent(Ale.Label.Domain.getTitleByValue("is_active",item.status))
                    )
                  }
                },
                {
                    key: 'description',
                    name: Ale.Label.Get("L_DESCRIPTION"),
                    fieldName: 'description',
                    //minWidth: 70,
                    //maxWidth: 90,
                    isResizable: true,
                    isCollapsible: true,
                    data: 'string',
                    onColumnClick: this.onColumnClick,
                    onRender: (item) => {
                        return (
                            this.onCellContent(item.description)
                        )
                      }
                  },
                {
                    key: 'updated',
                    name: Ale.Label.Get("L_UPDATED"),
                    fieldName: 'updated',
                    minWidth: 256,
                    maxWidth:256,
                    isResizable: true,
                    isCollapsible: true,
                    data: 'date',
                    onColumnClick: this.onColumnClick,
                    onRender: (item) => {
                        return (
                            this.onCellContent(item.updated)
                        )
                      }
                  }
              ]
        };
    }

    componentDidMount ()
    {
        console.log("componentDidMount")
        this.getData()
    }

    getData()
    {
        this.setState({ loading: true })
        var params = {
            token    : sessionStorage.getItem("token")
        };

        var c1 = Ale.Api.Call("Gate/Rbac/ApiList/",params);
        let self = this;

        c1.then(function(r)
        {
            if (Ale.Api.ErrorCheck(r))
            {
                self.setState({ loading: false })
                self.setState({ error: true,  error_code:r.error})
            }
            else
            {
                self.setState({ loading: false, items: r.result, items_copy: r.result})
                self.getTree()
            }
        }).catch(function(e)
        {
            //Ale.Ui.Loader.Hide();
        });
    }

    onCellContent(value)
    {
        //console.log(value)
        return(
            <div className="ale-list-cell">
                <div className="ale-center-vertical">
                    {value}
                </div>
            </div>
        )
    }

    onColumnClick (ev, column)
    {
        const { columns, items } = this.state;
        const newColumns = columns.slice();
        const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0];

        newColumns.forEach((newCol) => {
            if (newCol === currColumn)
            {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            }
            else
            {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });

        const newItems = _copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);
        this.setState({
            columns: newColumns,
            items: newItems
        });


        function _copyAndSort(items, columnKey, isSortedDescending)
        {
            const key = columnKey;
                return items.slice(0).sort((a, b) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
        }
    };

    onChangeText (ev, text) 
    {
        text     = text.toLowerCase();
        this.setState({
            items: text ? this.state.items_copy.filter(i => i.evt.toLowerCase().indexOf(text) > -1) : this.state.items_copy
        });

    };
    
    onItemInvoked(item)
    {
        //alert(`Item invoked: ${item.id}`);
        alert(item.description)
    }

    onGetExample(item)
    {
        this.setState({hideExample:false,curr_item:item})
    }

    onToggleStatus(item)
    {
        var data = {
            token   : sessionStorage.getItem("token"),
            id      : item.id,
            status  : item.status === "1" ? "0" : "1"
        };

        var c1 = Ale.Api.Call("Gate/Rbac/ApiUpdate/",data);

        let self=this;

        c1.then(function(r) 
        {
            if (Ale.Api.ErrorCheck(r))
            {
                self.setState({ error_text:r.error + ": " + r.description, successApiCall: false});
            }
            else
            {
                self.setState({ error_text: "", successApiCall: true });
                self.getData();
            }

        }).catch(function(e)
        {

        });
    }

    onModify(item)
    {
        ReactDOM.render(
            <AleApiForm item={item}/>,
            document.getElementById('ale-content')
        )
    }

    onAddApi()
    {
        console.log("onAddApi");

        ReactDOM.render(
            <AleApiForm />,
            document.getElementById('ale-content')
        )
    }

    render()
    {
        return (
        <>

            <div id="ale-sub-navbar" className="flex-row sticky-top d-flex ale-sub-navbar">
                <CommandBarButton
                    data-automation-id="cbb-1"
                    iconProps={{ iconName: 'Add' }}
                    text={Ale.Label.Get("L_ADD_API")}
                    onClick={this.onAddApi}
                />

            </div>

            <div className="flex-row p-3">
                <div style={{width: "300px"}}>
                    <TextField 
                        placeholder={Ale.Label.Get("L_FILTER_BY_EVENT")} 
                        underlined 
                        iconProps={{ iconName: 'Search' }} 
                        onChange={this.onChangeText}
                    />
                </div>
            </div>
            
            <div id="ale-section" className="flex-row w-100 p-3">
                { this.state.loading === false &&

                    <DetailsList
                        items={this.state.items}
                        compact={true}
                        columns={this.state.columns}
                        selectionMode={SelectionMode.none}
                        //setKey="set"
                        layoutMode={DetailsListLayoutMode.justified}
                        isHeaderVisible={true}
                        //selection={this._selection}
                        //selectionPreservedOnEmptyClick={true}
                        onItemInvoked={this.onItemInvoked}
                        //enterModalSelectionOnTouch={true}
                        //ariaLabelForSelectionColumn="Toggle selection"
                        //ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                        className={"ale-list"}
                    />
                }

                { this.state.loading === true &&
                    <Spinner size={SpinnerSize.large}  />
                }

                { this.state.error === true &&
                    <AleError error_code={this.state.error_code}/>
                }
                
                <Dialog
                   
                    hidden={this.state.hideExample}
                    onDismiss={() => this.setState({hideExample:true})}
                    maxWidth="50%"
                    dialogContentProps={{
                        isMultiline:true,
                        type: DialogType.normal,
                        title: `{{url}}/${this.state.curr_item.service}/${this.state.curr_item.obj}/${this.state.curr_item.evt}/`,
                        //subText: JSON.stringify(JSON.parse(atob(this.state.curr_item.example)),null,4)
                      
                    }}
                    modalProps={{
                        isBlocking: false,
                        //styles: { main: { minWidth: 450 } },
                        dragOptions: true,
                    }}
                >
                    <pre className="ale-color-text">

                        {JSON.stringify(JSON.parse(atob(this.state.curr_item.example)),null,4)}

                    </pre>
                    <DialogFooter>
                        <PrimaryButton onClick={() => this.setState({hideExample:true})} text={Ale.Label.Get("L_CANCEL")} />
                    </DialogFooter>
                </Dialog>

            </div>

        </>
    );
  }
}