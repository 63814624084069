import React    from 'react';
import ReactDOM from 'react-dom';

import { mergeStyles } from 'office-ui-fabric-react';

import './ale-sdk/my-app.css'

import AppRoute     from './ale-components/ale-app-route';

import * as serviceWorker          from './serviceWorker';

import { loadTheme } from 'office-ui-fabric-react';
import theme         from './ale-sdk/fabric-theme.json'

loadTheme({
    palette: theme
  });


mergeStyles({
    selectors:
    {
        ':global(body), :global(html), :global(#root)':
        {
            margin: 0,
            padding: 0,
            height: '100vh'
        }
    }
});

ReactDOM.render(
    <AppRoute/>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();