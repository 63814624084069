import React     from 'react';

import AleNavbar from './ale-navbar' 
import AleMenu   from './ale-menu';

export default class AleDashboard extends React.Component
{
    constructor(props)
    {
        super(props);

        console.log("Component AleLogin")

        this.state =
        {
        }

        this.componentDidMount      = this.componentDidMount.bind(this);
        this.onChange               = this.onChange.bind(this);
        this.onSubmit               = this.onSubmit.bind(this);
    }

    componentDidMount ()
    {
        console.log("componentDidMount")
    }

    onChange(e)
    {
        console.log("onChange")

        let value  = e.target.value;
        let name   = e.target.name;
    }

    onSubmit(e)
    {
    }

    render() 
    {

        return (
            <>

                <div className="container-fluid h-100 ale-layout-dashboard">

                    <div id="ale-nav" className="flex-row fixed-top d-flex ale-nav">
                        <AleNavbar/>
                    </div>

                    <div id="ale-main" className="flex-row d-flex h-100 ale-main">
                        
                        <div id="ale-menu" className="col-md-2 ale-menu">
                            <AleMenu/>
                        </div>

                        <div id="ale-content" className="col-md-10 ale-content">

                            {/* <div id="ale-sub-navbar" className="flex-row sticky-top d-flex ale-sub-navbar">
                            <AleSubNavBar />
                            </div>

                            <div id="ale-section" className="flex-row ale-section pl-2 pr-2">
                                   <AleDatamodelTree/>
                            </div> */}
                        </div>
                    </div>


                </div>

            </>
        );
    };
}