import React from 'react';
import Ale   from '../ale-sdk/ale-mobile'

import {Stack,TextField,PrimaryButton,Text,Dropdown,MessageBar,MessageBarType} from 'office-ui-fabric-react';

/*
service,
obj
evt
description
status
example
*/
export default class AleApiAdd extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state =
        {
            action         : "create",
            service        : "",
            object         : "",
            event          : "",
            status         : "",
            description    : "",
            example        : "",
            successApiCall : false,
            error_text     : ""
        }

        this.textOnChange   = this.textOnChange.bind(this);
        this.selectOnChange = this.selectOnChange.bind(this);
        this.onSubmit       = this.onSubmit.bind(this);
    }

    componentDidMount()
    {
        if(this.props.hasOwnProperty('item'))
        {
            this.setState({ service:     this.props.item.service, 
                            object:      this.props.item.obj,
                            event:       this.props.item.evt,
                            description: this.props.item.description,
                            example:     atob(this.props.item.example),
                            status:      this.props.item.status,
                            action:      "update"});
        }
    }

    textOnChange(e)
    {
        let name = e.target.name;
        let value = e.target.value;

        if(name === "service" || name === "object" || name === "event")
        {
            value = value.replace(/[^a-zA-Z]/g, '');
        }
        else if(name === "description")
        {
            value = value.replace(/[^a-zA-Z ,.]/g, '');
        }

        this.setState({[name]: value});
    }

    selectOnChange(e, item)
    {
        let id = e.target.id;
        this.setState({[id]: item.key});
    }

    onSubmit()
    {
        var data = {
            token        : sessionStorage.getItem("token"),
            service_name : this.state.service,
            object_name  : this.state.object,
            event_name   : this.state.event,
            example      : this.state.example,
            description  : this.state.description,
            status       : this.state.status
        };

        var api = "Gate/Rbac/ApiCreate/";
        if(this.state.action === 'update')
        {
            api = "Gate/Rbac/ApiUpdate/"
            data.id = this.props.item.id;
        }

        var c1 = Ale.Api.Call(api,data);

        let self=this;

        c1.then(function(r) 
        {
            if (Ale.Api.ErrorCheck(r))
            {
                self.setState({ error_text:r.error + ": " + r.description, successApiCall: false});
            }
            else
            {
                self.setState({ error_text: "", successApiCall: true });
            }

        }).catch(function(e)
        {

        });
    }

    render() 
    {
        const fieldgroupProps =
        {
            tokens: { childrenGap: "5%"},
            styles: { root: { width:"75%"} },

        };

        return (

            <>
            <div id="ale-sub-navbar" className="flex-row sticky-top d-flex ale-sub-navbar justify-content-center">
                <Text block>Add a new api</Text>
            </div>

            <div id="ale-section" className="flex-row w-100 p-3 ">
               
                <div className="flex-row w-100 ale-background-darker" style={{overflowY:"scroll"}}>

                    <div className="col-md-6 float-left h-100 p-3 ale-background-darker">

            
                        <Stack {...fieldgroupProps} className={"ale-form-default"}>
                            <TextField name='service'     underlined label={Ale.Label.Get("L_SERVICE_NAME")} type="text"  required onChange={this.textOnChange} value={this.state.service} disabled={this.state.action === 'update' ? true : false}/>
                            <TextField name='object'      underlined label={Ale.Label.Get("L_OBJ_NAME")}     type="text"  required onChange={this.textOnChange} value={this.state.object}  disabled={this.state.action === 'update' ? true : false}/>
                            <TextField name='event'       underlined label={Ale.Label.Get("L_EVT_NAME")}     type="text"  required onChange={this.textOnChange} value={this.state.event}   disabled={this.state.action === 'update' ? true : false}/>
                            <Dropdown
                                id = 'status'
                                placeholder={Ale.Label.Get("L_STATUS")}
                                onChange={this.selectOnChange}
                                options={[{ key: "1", text: Ale.Label.Domain.getTitleByValue("is_active","1")},
                                          { key: "0", text: Ale.Label.Domain.getTitleByValue("is_active","0")}  ]}
                                required={true}
                                selectedKey={this.state.status}
                            />
                        </Stack>

                    </div>

                    <div className="col-md-6 float-left h-100 p-3 ale-background-darker">
                        <Stack {...fieldgroupProps} className={"ale-form-default"}>
                            
                            <TextField name='description' underlined label={Ale.Label.Get("L_DESCRIPTION")}    type="text"  required onChange={this.textOnChange} value={this.state.description}/>
                            <TextField name='example' underlined label={Ale.Label.Get("L_EXAMPLE")}            type="text"  required onChange={this.textOnChange} value={this.state.example}/>   

                        </Stack>
                    </div>

                    <div className="row w-100 p-3 justify-content-center">

                            <PrimaryButton text={this.state.action === 'update' ? Ale.Label.Get("L_EDIT_API") : Ale.Label.Get("L_ADD_API")}
                                onClick={this.onSubmit} 
                            />

                    </div>

                    <div className="row w-100 p-3 justify-content-center">
                        { this.state.error_text !== "" &&
                            //<Stack className={{display: 'flex',alignItems: 'left',justifyContent: 'center'}}>
                                <MessageBar messageBarType={MessageBarType.error} isMultiline={false} dismissButtonAriaLabel="Close">
                                    {this.state.error_text}
                                </MessageBar>
                            //</Stack>
                        }

                        { this.state.successApiCall &&
                            <MessageBar messageBarType={MessageBarType.success} isMultiline={false}>
                                {this.state.action === 'update' ? Ale.Label.Get("L_DATA_MODEL_ELEMENT_UPDATED") : Ale.Label.Get("L_DATA_MODEL_ELEMENT_ADDED")}
                            </MessageBar>
                       }
                    </div>

                </div>
        </div>
        </>
        )
    }
}