import React from 'react';
import Ale   from '../ale-sdk/ale-mobile'

import {PrimaryButton, Dialog, DialogType, DialogFooter} from 'office-ui-fabric-react';



export default class AleError extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state =
        {
            hideDialog  : false,
            isDraggable : false
        }

        this.closeDialog  = this.closeDialog.bind(this);
    }



    closeDialog ()
    {
        this.setState({ hideDialog: true });
    };


    render() 
    {

        return (
            <Dialog
            hidden={this.state.hideDialog}
            onDismiss={this.closeDialog}
            dialogContentProps={{
              type: DialogType.normal,
              title: Ale.Label.Get("Error"),
              subText: Ale.Label.Get("Error-"+this.props.error_code),
            }}
            modalProps={{
             
              isBlocking: false,
              styles: { main: { maxWidth: 450 } },
              dragOptions: this.state.isDraggable
            }}
          >
            <DialogFooter>
              <PrimaryButton onClick={this.closeDialog} text={Ale.Label.Get("L_CANCEL")} />
            </DialogFooter>
          </Dialog>
        )
    }
}