import React from 'react';
import Ale   from '../ale-sdk/ale-mobile'

import { TextField, Stack, Dropdown, Checkbox, PrimaryButton, Label,Separator } from 'office-ui-fabric-react';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react/lib/MessageBar';

const style = {
    stackitem : {
        height: 55,
        display: 'flex',
        alignItems: 'left',
        justifyContent: 'center',
//        background: DefaultPalette.themePrimary
    },
    stackbutton : {
        height: 55,
        display: 'flex',
        alignItems: 'right',
        justifyContent: 'center',
//        background: DefaultPalette.themePrimary
    }
}

const tokens = {
    parent : { 
        childrenGap: 25, 
        padding: 10 
    },
    child : {
        childrenGap : 50
    },
    button : {
        childrenGap : 10
    }
}  

export default class AleDataModelTreeDetail extends React.Component
{
    constructor(props)
    {
        super(props)

        this.state =
        {
            viewId          : this.props.curr_node_id,
            isLoading       : true,
            data            : [],
            dmLoading       : true,
            domainList      : [],
            regexLoading    : true,
            regexList       : [],
            in_create       : false,
            in_search       : false,
            in_update       : false,
            is_column       : false,
            required        : false,
            regex           : "",
            type            : "",
            name            : "",
            db_table        : "",
            k_d_table       : "",
            domain          : "",
            column_name     : "",
            in_create_order : "0",
            max_value       : "0",
            min_value       : "0",
            updated_details : "",
            addButtonPressed: false,
            successApiCall  : false,
            error_text      : "",
            formTitle       : "",
            viewFields      :
            {
                name                : false,
                type                : false,
                regex               : false,
                db_table            : false,
                k_d_table           : false,
                domain              : false,
                is_column           : false,
                column_name         : false,
                in_create           : false,
                in_search           : false,
                in_update           : false,
                required            : false,
                in_create_order     : false,
                min_value           : false,
                max_value           : false
            }
        }

        this.componentDidMount      = this.componentDidMount.bind(this);
        this.onSubmit               = this.onSubmit.bind(this);
        this.tickCheckBox           = this.tickCheckBox.bind(this);
        this.textOnChange           = this.textOnChange.bind(this);
        this.selectOnChange         = this.selectOnChange.bind(this);
        this.getDataModel           = this.getDataModel.bind(this);
        this.getDomainList          = this.getDomainList.bind(this);
        this.generateDomainList     = this.generateDomainList.bind(this);
        this.submitForm             = this.submitForm.bind(this);
        this.cancelEdit             = this.cancelEdit.bind(this);
    }

    componentDidMount ()
    {
        this.getDomainList();
        this.getRegexList();
        this.getDataModel();
        this.setState({formTitle: Ale.Label.Get("L_DATA_MODEL_EDIT_ITEM")})
    }

    componentDidUpdate(oldProps) 
    {
        const newProps = this.props
        
        if(oldProps.curr_node_id !== newProps.curr_node_id) 
        {
            this.getDomainList();
            this.getRegexList();
            this.getDataModel();
            this.setState({addButtonPressed: false, error_text: "", successApiCall: false, formTitle: Ale.Label.Get("L_DATA_MODEL_EDIT_ITEM")});
        }

        if(oldProps.action !== newProps.action)
        {
            let id = newProps.action;
            this.showFormElements(id, newProps.regex);
            this.initFormElements(id);
            this.setState({error_text: ""})

            if(newProps.action !== "")
            {
                this.setState({addButtonPressed: true, formTitle: Ale.Label.Get("L_DATA_MODEL_ADD_ITEM")});
            }
        }
    }

    onSubmit(e)
    {
        e.preventDefault();
        e.stopPropagation();
    }

    showFormElements(id, regex)
    {
        id = id.toUpperCase();
        let min_max_visible=false;

        if( regex === "number-integer" || regex === "number-float" || regex === "string-free" ||
            regex === "string-alphanumeric" || regex === "string-strict" || regex === "string-proper-noun")
        {
            min_max_visible = true;
        }

        if (id === "Add-Root".toUpperCase() || id === "Add-Class".toUpperCase())
        {
            this.setState({
                viewFields      :
                {
                    name                : true,
                    type                : true,
                    regex               : false,
                    db_table            : false,
                    k_d_table           : false,
                    domain              : false,
                    is_column           : false,
                    column_name         : false,
                    in_create           : false,
                    in_search           : false,
                    in_update           : false,
                    required            : false,
                    in_create_order     : false,
                    min_value           : false,
                    max_value           : false
                }
            });
        }
        else if (id === "Add-Structure".toUpperCase() || id === "Add-Array-Of-Structure".toUpperCase())
        {
            this.setState({
                viewFields      :
                {
                    name                : true,
                    type                : true,
                    regex               : false,
                    db_table            : false,
                    k_d_table           : false,
                    domain              : false,
                    is_column           : false,
                    column_name         : false,
                    in_create           : true,
                    in_search           : true,
                    in_update           : true,
                    required            : true,
                    in_create_order     : true,
                    min_value           : false,
                    max_value           : false
                }
            });
        }
        else if (id === "Add-Array".toUpperCase() || id === "Add-Field".toUpperCase())
        {
            this.setState({
                viewFields      :
                {
                    name                : true,
                    type                : true,
                    regex               : true,
                    db_table            : false,
                    k_d_table           : false,
                    domain              : false,
                    is_column           : false,
                    column_name         : false,
                    in_create           : true,
                    in_search           : true,
                    in_update           : true,
                    required            : true,
                    in_create_order     : true,
                    min_value           : min_max_visible,
                    max_value           : min_max_visible
                }
            });
        }
        else if (id === "Add-Domain".toUpperCase())
        {
            this.setState({
                viewFields      :
                {
                    name                : true,
                    type                : true,
                    regex               : true,
                    db_table            : false,
                    k_d_table           : false,
                    domain              : true,
                    is_column           : false,
                    column_name         : false,
                    in_create           : true,
                    in_search           : true,
                    in_update           : true,
                    required            : true,
                    in_create_order     : true,
                    min_value           : false,
                    max_value           : false
                }
            });
        }
    }

    initFormElements(id)
    {
        id = id.toUpperCase();

        if (id === "Add-Root".toUpperCase())
        {
            this.setState({
                type     : "root",
                name     : "",
                updated_details : ""
            });
        }
        else if (id === "Add-Class".toUpperCase())
        {
            this.setState({
                type     : "class",
                name     : "",
                db_table : "",
                updated_details : ""
            });
        }
        else if (id === "Add-Structure".toUpperCase() || id === "Add-Array-Of-Structure".toUpperCase())
        {
            this.setState({
                type            : "structure",
                name            : "",
                in_create_order : "",
                in_create       : true,
                in_update       : true,
                in_search       : true,
                required        : true,
                updated_details : ""
            });

            if(id === "Add-Array-Of-Structure".toUpperCase())
            {
                this.setState({
                    type : "array-of-structure"
                })
            }
        }
        else if (id === "Add-Array".toUpperCase())
        {
            this.setState({
                type            : "array",
                name            : "",
                regex           : "",
                in_create_order : "",
                min_value       : "",
                max_value       : "",
                in_create       : true,
                in_update       : true,
                in_search       : true,
                required        : true,
                updated_details : ""
            });
        }
        else if (id === "Add-Field".toUpperCase())
        {
            this.setState({
                type            : "field",
                name            : "",
                regex           : "",
                in_create_order : "",
                min_value       : "",
                max_value       : "",
                in_create       : true,
                in_update       : true,
                in_search       : true,
                required        : true,
                is_column       : false,
                column_name     : "",
                k_d_table       : "",
                updated_details : ""
            });
        }
        else if (id === "Add-Domain".toUpperCase())
        {
            this.setState({
                type            : "field",
                name            : "",
                regex           : "domain",
                in_create_order : "",
                in_create       : true,
                in_update       : true,
                in_search       : true,
                required        : true,
                is_column       : false,
                column_name     : "",
                k_d_table       : "",
                updated_details : "",
                domain          : ""
            });
        }
    }

    tickCheckBox(e)
    {
        let name = e.target.id;
        this.setState({[name]: e.target.checked});
    }

    textOnChange(e)
    {
        let name = e.target.name;
        let value = e.target.value;

        if(name === "in_create_order" || name === "min_value" || name === "max_value")
        {
            value = value.replace(/[^0-9]/g, '');
        }
        else if(name === "name" || name === "db_table" || name === "column_name")
        {
            value = value.replace(/[^a-zA-Z_]/g, '');
        }
        else if(name === "type")
        {
            value = value.replace(/[^a-zA-Z-]/g, '');
        }
        else if(name === "k_d_table")
        {
            value = value.replace(/[^a-zA-Z._]/g, '');
        }

        this.setState({[name]: value});
    }
    
    selectOnChange(e, item)
    {
        let id = e.target.id;
        let min_max_visible = false;
        console.log("seletOnChnag3");
        console.log(item.key)

        if(item.key === "number-integer" || item.key === "number-float" || item.key === "string-free" ||
           item.key === "string-alphanumeric" || item.key === "string-strict" || item.key === "string-proper-noun")
        {
            min_max_visible = true;
        }

        this.setState({[id]: item.key, viewFields: { ...this.state.viewFields, min_value: min_max_visible, max_value: min_max_visible}});
    }

    render()
    {
        const { dmLoading, regexLoading } = this.state;

        let domainSelectList = "";
        let reg_exp_list = [];

        if(!dmLoading)
        {
            domainSelectList = this.generateDomainList();
        }

        if(this.state.regex === "domain")
        {
            reg_exp_list = [
                { key: 'domain', text: Ale.Label.Get("L_REGEXP_DOMAIN") }
            ];
        }
        else if(!regexLoading)
        {
            reg_exp_list = this.generateRegexList();
        }

        return(
            <Stack tokens={tokens.parent}>
                <Separator>{this.state.formTitle}</Separator>
                             
                <Stack horizontal tokens={tokens.child} >
                    <Stack.Item grow={1} className={style.stackitem}>
                        {this.getTextField("name", this.state.name, Ale.Label.Get("L_DATA_MODEL_NAME"), this.state.viewFields.name, true, true, !this.state.addButtonPressed)}
                    </Stack.Item>
                    <Stack.Item grow={1} className={style.stackitem}>
                        {this.getTextField("type", this.state.type, Ale.Label.Get("L_TYPE"), this.state.viewFields.type, true, true, true)}
                    </Stack.Item>
                </Stack>

                <Stack horizontal tokens={tokens.child}>
                    <Stack  grow={1} className={style.stackitem}>
                        {this.getDropDown("regex", Ale.Label.Get("L_REGEXP"), this.state.regex, reg_exp_list, this.state.viewFields.regex)}
                    </Stack>
                    <Stack  grow={1} className={style.stackitem}>
                        {this.getTextField("db_table", this.state.db_table, Ale.Label.Get("L_DATA_MODEL_TABLE_NAME"), this.state.viewFields.db_table, false, true)}
                    </Stack>
                </Stack>

                <Stack horizontal tokens={tokens.child}>
                    <Stack  grow={1} className={style.stackitem}>
                        {this.getDropDown("domain", Ale.Label.Get("L_DOMAIN_LIST"), this.state.domain, domainSelectList, this.state.viewFields.domain)}
                    </Stack>
                    <Stack  grow={1} className={style.stackitem}>
                    {this.getTextField("k_d_table", this.state.k_d_table, Ale.Label.Get("L_VALUE_TABLE_NAME"), this.state.viewFields.k_d_table, false, true)}
                    </Stack>
                </Stack>

                <Stack horizontal tokens={tokens.child}>
                    <Stack  grow={1} className={style.stackitem}>
                        {this.getCheckBox(this.state.is_column, "is_column", Ale.Label.Get("L_DATA_MODEL_IS_COLUMN"), this.state.viewFields.is_column)}
                    </Stack>
                    <Stack  grow={5} className={style.stackitem}>
                        {this.getTextField("column_name", this.state.column_name, Ale.Label.Get("L_DATA_MODEL_COLUMN_NAME"), this.state.viewFields.column_name, true, this.state.is_column)}
                    </Stack>
                </Stack>

                <Stack horizontal tokens={tokens.child}>
                    <Stack  grow={1} className={style.stackitem}>
                        {this.getCheckBox(this.state.in_create, "in_create", Ale.Label.Get("L_DATA_MODEL_IN_CREATE"), this.state.viewFields.in_create)}
                    </Stack>
                    <Stack grow={1} className={style.stackitem}>
                        {this.getCheckBox(this.state.in_search, "in_search", Ale.Label.Get("L_DATA_MODEL_IN_SEARCH"), this.state.viewFields.in_search)}
                    </Stack>
                    <Stack grow={1} className={style.stackitem}>
                        {this.getCheckBox(this.state.in_update, "in_update", Ale.Label.Get("L_DATA_MODEL_IN_UPDATE"), this.state.viewFields.in_update)}
                    </Stack>
                    <Stack grow={1} className={style.stackitem}>
                        {this.getCheckBox(this.state.required, "required", Ale.Label.Get("L_DATA_MODEL_REQUIRED"), this.state.viewFields.required)}
                    </Stack>
                </Stack>
             
                <Stack horizontal tokens={tokens.child}>
                    <Stack grow={1} className={style.stackitem}>
                        {this.getTextField("in_create_order", this.state.in_create_order, Ale.Label.Get("L_DATA_MODEL_IN_CREATE_ORDER"), this.state.viewFields.in_create_order, true)}
                    </Stack>
                    <Stack grow={1} className={style.stackitem}>
                        {this.getTextField("min_value", this.state.min_value, Ale.Label.Get("L_DATA_MODEL_MIN_VALUE"), this.state.viewFields.min_value, true, true)}
                    </Stack>
                    <Stack grow={1} className={style.stackitem}>
                        {this.getTextField("max_value", this.state.max_value, Ale.Label.Get("L_DATA_MODEL_MAX_VALUE"), this.state.viewFields.max_value, true, true)}
                    </Stack>
                </Stack>


                <Stack horizontal tokens={tokens.button} >
                    <Stack grow={10} className={style.stackitem}>
                        <Label>
                            {this.state.updated_details}
                        </Label>
                    </Stack>
                    <Stack grow={1} className={style.stackbutton}>
                        <PrimaryButton
                            data-automation-id="SubmitButton"
                            text={Ale.Label.Get("L_DATA_MODEL_SUBMIT")}
                            onClick={this.submitForm}
                            allowDisabledFocus={true}
                            style={{'maxWidth': '80px'}}
                        />
                    </Stack>
                    <Stack grow={1} className={style.stackbutton}>
                        <PrimaryButton
                            data-automation-id="CancelButton"
                            text={Ale.Label.Get("L_DATA_MODEL_CANCEL")}
                            onClick={this.cancelEdit}
                            allowDisabledFocus={true}
                            style={{'maxWidth': '80px'}}
                        />
                    </Stack>
                </Stack>

                { this.state.error_text !== "" &&
                    <Stack className={style.stackitem}>
                        <MessageBar messageBarType={MessageBarType.error} isMultiline={false} dismissButtonAriaLabel="Close">
                            {this.state.error_text}
                        </MessageBar>
                    </Stack>
                }

                { this.state.successApiCall === "create" &&
                    <Stack className={style.stackitem}>
                        <MessageBar messageBarType={MessageBarType.success} isMultiline={false}>
                            {Ale.Label.Get("L_DATA_MODEL_ELEMENT_ADDED")}
                        </MessageBar>
                    </Stack>
                }

                { this.state.successApiCall === "update" &&
                    <Stack className={style.stackitem}>
                        <MessageBar messageBarType={MessageBarType.success} isMultiline={false}>
                            {Ale.Label.Get("L_DATA_MODEL_ELEMENT_UPDATED")}
                        </MessageBar>
                    </Stack>
                }
            </Stack>
        );
    }

    getTextField(id, value, label, display=false, underlined=false, required=false, disabled=false)
    {
        if(display){
            return(
                <TextField
                    id={id}
                    name={id}
                    label={label + ":"}
                    value={value}
                    underlined={underlined}
                    onChange={this.textOnChange}
                    disabled={disabled}
                    required={required}
                />
            );
        };

        return null;
    }

    getCheckBox(checked, id, label, display)
    {
        if(display){
            return(
                <Checkbox 
                    label={label}
                    onChange={this.tickCheckBox}
                    checked={checked}
                    id = {id}
                />
            );
        };

        return null;
    }

    getDropDown(id, label, chosenValue, options, display)
    {
        if(display){
            return(
                <Dropdown
                    placeholder={label}
                    label={label}
                    id={id}
                    selectedKey={chosenValue}
                    onChange={this.selectOnChange}
                    options={options}
                    required={true}
                    styles={{ dropdown: { minWidth: 200 } }}
                />
            );
        };

        return null;
    }

    generateDomainList()
    {
        let dmList = [];

        if(!this.state.dmLoading)
        {
            for(var i = 0; i < this.state.domainList.result.length; i++)
            {
                let domainName = this.state.domainList.result[i].domain;
                dmList.push({ key: domainName, text: domainName})
            }
        }

        return dmList;
    }

    generateRegexList()
    {
        let regexList = [];

        if(!this.state.regexLoading)
        {
            let reg_list = this.state.regexList.result.split(",");
            for(var i = 0; i < reg_list.length; i++)
            {
                let regex_text = reg_list[i];
                regex_text     = regex_text.replace(/-/g, "_");
                regex_text     = regex_text.toUpperCase();
                regex_text     = Ale.Label.Get("L_REGEXP_" + regex_text);
                regexList.push({ key: reg_list[i], text: regex_text})
            }
        }

        return regexList;
    }

    getDataModel()
    {
        var data = {
            token    : sessionStorage.getItem("token"),
            id       : this.props.curr_node_id
        };

        var c1 = Ale.Api.Call(this.props.service+"/DataModel/Read/",data);
        let self = this;
        
        c1.then(function(r) 
        {
            Ale.Api.ErrorHandle(r);

            let name   = r.result[0].dn;
            name       = name.replace(/\.\[\]/g, "");
            name       = name.substring(name.lastIndexOf(".")+1, name.length);

            let domain = r.result[0].k_d_table;
            domain     = domain.substring(domain.lastIndexOf(".")+1, domain.length);

            let type   = r.result[0].type;
            let regex  = r.result[0].reg_exp;

            self.setState({
                data            : r,
                isLoading       : false,
                in_create       : r.result[0].in_create === "1",
                in_search       : r.result[0].in_search === "1",
                in_update       : r.result[0].in_update === "1",
                is_column       : r.result[0].is_column === "1",
                required        : r.result[0].required  === "1",
                regex           : regex,
                type            : type,
                name            : name,
                db_table        : r.result[0].db_table,
                k_d_table       : r.result[0].k_d_table,
                domain          : domain,
                in_create_order : r.result[0].in_create_order,
                max_value       : r.result[0].max_value,
                min_value       : r.result[0].min_value,
                column_name     : r.result[0].column_name,
                updated_details : r.result[0].updated + " - " + r.result[0].updated_by
            });

            let id = "Add-"+type;

            if(r.result[0].reg_exp === "domain")
            {
                id = "Add-Domain";
            }
            self.showFormElements(id, regex);

        }).catch(function(e)
        {
            //Ale.Ui.Loader.Hide();
        });
    }

    getDomainList()
    {
        var data = {
            token    : sessionStorage.getItem("token")
        };
        var c1 = Ale.Api.Call(this.props.service+"/DataModel/ListDomains/",data);

        let self = this;
        
        c1.then(function(r) 
        {
            Ale.Api.ErrorHandle(r);

            self.setState({
                dmLoading : false,
                domainList: r
            });

        }).catch(function(e)
        {
            //Ale.Ui.Loader.Hide();
        });
    }

    getRegexList()
    {
        var data = {
            token    : sessionStorage.getItem("token")
        };
        var c1 = Ale.Api.Call(this.props.service+"/DataModel/ListRegex/",data);

        let self = this;
        
        c1.then(function(r) 
        {
            Ale.Api.ErrorHandle(r);

            self.setState({
                regexLoading : false,
                regexList: r
            });

        }).catch(function(e)
        {
            //Ale.Ui.Loader.Hide();
        });
    }

    submitForm(e)
    {
        if(this.state.addButtonPressed)
        {
            if(this.state.type === "root")
            {
                this.createRoot();
            }
            else if (this.state.type === "class")
            {
                this.createClass();
            }
            else if (this.state.type === "structure" || this.state.type === "array-of-structure")
            {
                this.submitStructure("create");
            }
            else if (this.state.type === "field" && this.state.regex === "domain")
            {
                this.submitDomain("create");
            }
            else if (this.state.type === "field" || this.state.type === "array")
            {
                this.submitFieldArr("create");
            }
        }
        else
        {
            if (this.state.type === "field" && this.state.regex === "domain")
            {
                this.submitDomain("update");
            }
            else if (this.state.type === "field" || this.state.type === "array")
            {
                this.submitFieldArr("update");
            }
            else if (this.state.type === "structure" || this.state.type === "array-of-structure")
            {
                this.submitStructure("update");
            }
        }
    }

    createRoot()
    {
        var data = {
            token    : sessionStorage.getItem("token"),
            dn       : this.state.name
        };

        this.CallSubmitAPI(data, this.props.service+"/DataModel/CreateRoot/", "create");
    }

    createClass()
    {
        var data = {
            token     : sessionStorage.getItem("token"),
            dn_name   : this.state.name,
            parent_id : this.props.curr_node_id
        };

        this.CallSubmitAPI(data, this.props.service+"/DataModel/CreateClass/", "create");
    }

    submitStructure(action)
    {
        var data = {
            token           : sessionStorage.getItem("token"),
            in_create       : this.state.in_create ? "1" : "0",
            in_update       : this.state.in_update ? "1" : "0",
            in_search       : this.state.in_search ? "1" : "0",
            required        : this.state.required  ? "1" : "0",
            in_create_order : this.state.in_create_order
        };

        let api = "";
        if(action==="create" && this.state.type === "structure")
        {
            data.dn = this.state.name;
            data.parent_id = this.props.curr_node_id;
            api = this.props.service+"/DataModel/CreateStructure/";
        }
        else if(action==="create" && this.state.type === "array-of-structure")
        {
            data.dn = this.state.name;
            data.parent_id = this.props.curr_node_id;
            api = this.props.service+"/DataModel/CreateArrayStr/";
        }
        else if(action==="update" && this.state.type === "structure")
        {
            data.id = this.props.curr_node_id;
            api = this.props.service+"/DataModel/UpdateStructure/";
        }
        else if(action==="update" && this.state.type === "array-of-structure")
        {
            data.id = this.props.curr_node_id;
            api = this.props.service+"/DataModel/UpdateArrayStr/";
        }

        this.CallSubmitAPI(data, api, action);
    }

    submitFieldArr(action)
    {
        var data = {
            token           : sessionStorage.getItem("token"),
            in_create       : this.state.in_create ? "1" : "0",
            in_update       : this.state.in_update ? "1" : "0",
            in_search       : this.state.in_search ? "1" : "0",
            required        : this.state.required  ? "1" : "0",
            in_create_order : this.state.in_create_order,
            regex           : this.state.regex
        };

        // min_value and max_value to be populated only if regex is one of "number-integer, 
        // number-float, string-alphanumeric, string-strict, string-free"
        if(this.state.regex === "number-integer" || this.state.regex === "number-float" ||
           this.state.regex === "string-alphanumeric" || this.state.regex === "string-strict" || 
           this.state.regex === "string-free" || this.state.regex === "string-proper-noun")
        {
            data.min_value = this.state.min_value;
            data.max_value = this.state.max_value;
        }
            

        if(action === "create" && this.state.type === "array")
        {
            data.dn = this.state.name;
            data.parent_id = this.props.curr_node_id;
            this.CallSubmitAPI(data, this.props.service+"/DataModel/CreateArray/", action);
        }
        else if(action === "update" && this.state.type === "array")
        {
            data.id = this.props.curr_node_id;
            this.CallSubmitAPI(data, this.props.service+"/DataModel/UpdateArray/", action);
        }
        else if(action === "create" && this.state.type === "field")
        {
            data.dn = this.state.name;
            data.parent_id = this.props.curr_node_id;
            this.CallSubmitAPI(data, this.props.service+"/DataModel/CreateField/", action);
        }
        else if(action === "update" && this.state.type === "field")
        {
            data.id = this.props.curr_node_id;
            this.CallSubmitAPI(data, this.props.service+"/DataModel/UpdateField/", action);
        }
    }

    submitDomain(action)
    {
        var data = {
            token           : sessionStorage.getItem("token"),
            domain          : this.state.domain,
            in_create       : this.state.in_create ? "1" : "0",
            in_update       : this.state.in_update ? "1" : "0",
            in_search       : this.state.in_search ? "1" : "0",
            required        : this.state.required  ? "1" : "0",
            in_create_order : this.state.in_create_order
        };

        if(action === "create")
        {
            data.dn = this.state.name;
            data.parent_id = this.props.curr_node_id;
            this.CallSubmitAPI(data, this.props.service+"/DataModel/CreateFldDomain/", action);
        }
        else if(action === "update")
        {
            data.id = this.props.curr_node_id;
            this.CallSubmitAPI(data, this.props.service+"/DataModel/UpdateFldDomain/", action);
        }
    }

    CallSubmitAPI(data, api, action)
    {
        var c1 = Ale.Api.Call(api,data);

        let self=this;

        c1.then(function(r) 
        {
            if (Ale.Api.ErrorCheck(r))
            {
                self.setState({ error_text:r.error + ": " + r.description, successApiCall: ""});
            }
            else
            {
                self.setState({viewId:r.result.id, addButtonPressed : false, successApiCall: action, formTitle: Ale.Label.Get("L_DATA_MODEL_EDIT_ITEM")});
                self.getDataModel();
                self.props.refreshData();
            }

        }).catch(function(e)
        {
            self.setState({addButtonPressed : false});
        });
    }

    cancelEdit()
    {
        this.getDataModel();
        this.setState({addButtonPressed : false, error_text:"", successApiCall: "", formTitle: Ale.Label.Get("L_DATA_MODEL_EDIT_ITEM")});
        this.props.formCancelled();
    }
}